import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import rlPageReducer from './slice/RegulatoryLineageDataSlice';
import thematicLineagePageReducer from './slice/ThematicLineageDataSlice';
import dataCategoryPageSlice from './slice/dataCategoryPageSlice';
import dataSourceGridSlice from './slice/dataSourcingGridSlice';
import dataSourcingPageSlice from './slice/dataSourcingPageSlice';
import homePageConfigReducer from './slice/homePageConfigSlice';
import homePageReducer from './slice/homePageDataSlice';
import pageLoaderReducer from './slice/pageLoaderSlice';
import sapHomePageSlice from './slice/sapHomePageSlice';
import sapLineagePageSlice from './slice/sapLineagePageSlice';
import userProfileSlice from './slice/userProfileSlice';

export const store = configureStore({
  reducer: {
    homePageSlice: homePageReducer,
    homePageConfigSlice: homePageConfigReducer,
    dataCategoryPageSlice: dataCategoryPageSlice,
    dataSourcingPageSlice: dataSourcingPageSlice,
    dataSourceGridSlice: dataSourceGridSlice,
    rlPageSlice: rlPageReducer,
    pageLoaderSlice: pageLoaderReducer,
    sapHomePageSlice: sapHomePageSlice,
    sapLineagePageSlice: sapLineagePageSlice,
    thematicLineagePageSlice: thematicLineagePageReducer,
    userProfileSlice: userProfileSlice
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
