import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SankeyExtraProperties, SankeyNode } from 'd3-sankey';
import {
  THEMATIC_LINEAGE_MAX_LEVEL_X,
  thematicLineageApi,
  thematicLineageRequestData
} from 'shared/helpers/constants';
import { ThreeMetrics } from 'shared/models/AppModel';
import { ExtraNodeProperties } from 'shared/models/Sankey';
import { httpClient } from 'shared/utils/httpClient';
import { processThematicRegulatoryData } from 'shared/utils/utils';

import { RootState } from '../store';

interface IThematicLineagePageSlice {
  thematicLineagePageData: any;
  pending: boolean;
  error?: string;
  metricCount: ThreeMetrics;
}

const initialState: IThematicLineagePageSlice = {
  metricCount: {
    primary: 0,
    coreData: 0,
    secondary: 0
  },
  thematicLineagePageData: {},
  pending: true,
  error: ''
};

interface IRequestParams {
  columnId: number;
  nodeId: number;
  parentKey: string;
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
}

const createRequestData = (
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
  columnId: number
) => {
  // if (columnId == THEMATIC_LINEAGE_MAX_LEVEL_X + 1) {
  //   const data = {
  //     obligationNode: selectedNodes.find((value) => {
  //       return value.levelX == 4;
  //     })?.key,
  //     coreAttributeNode: selectedNodes.find((value) => {
  //       return value.levelX == THEMATIC_LINEAGE_MAX_LEVEL_X;
  //     })?.key
  //   };
  //   return data;
  // } else {
  const data: any = {};
  for (let i = 0; i < columnId; i++) {
    if (thematicLineageRequestData.length > i) {
      const node = selectedNodes.find((value) => {
        return value.levelX == i;
      });
      if (node) data[thematicLineageRequestData[i]] = node.key;

      if (columnId == THEMATIC_LINEAGE_MAX_LEVEL_X + 1) {
        data['coreAttributeNode'] = selectedNodes.find((value) => {
          return value.levelX == THEMATIC_LINEAGE_MAX_LEVEL_X;
        })?.key;
      }
    }
  }
  return data;
  // }
};

export const fetchThematicLineageData = createAsyncThunk<any, IRequestParams, { state: RootState }>(
  'data/fetchThematicLineageData',
  async ({ columnId, nodeId, parentKey, selectedNodes }) => {
    const reqData = createRequestData(selectedNodes, columnId);
    return httpClient.post(thematicLineageApi.generateUrl(columnId), reqData).then(({ data }) => {
      if (columnId == THEMATIC_LINEAGE_MAX_LEVEL_X + 1) return data;
      const finalData = processThematicRegulatoryData(data.items, columnId, parentKey);
      return { data: { [nodeId]: finalData }, count: data.count };
    });
  }
);

const thematicLineagePageSlice = createSlice({
  name: 'thematicLineagePageSlice',
  initialState,
  reducers: {
    updateThematicMetricCount: (
      state: IThematicLineagePageSlice,
      action: PayloadAction<ThreeMetrics>
    ) => {
      state.metricCount = action.payload;
    },
    deleteThematicNodeData: (state: IThematicLineagePageSlice, action: PayloadAction<any>) => {
      action.payload.map((id: any) => {
        delete state.thematicLineagePageData[id];
      });
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchThematicLineageData.fulfilled, (state, responseData) => {
      state.pending = false;
      state.thematicLineagePageData = {
        ...state.thematicLineagePageData,
        ...responseData?.payload?.data
      };
      state.metricCount = responseData?.payload?.count
        ? responseData.payload.count
        : state.metricCount;
    });
    builder.addCase(fetchThematicLineageData.rejected, (state, responseData) => {
      state.error = responseData?.error?.message ? responseData.error.message.toString() : '';
      state.thematicLineagePageData = {
        ...state.thematicLineagePageData,
        [responseData?.meta.arg.nodeId]: null
      };
      state.pending = false;
    });
    builder.addCase(fetchThematicLineageData.pending, (state) => {
      state.pending = true;
      state.error = '';
    });
  }
});

export const { updateThematicMetricCount, deleteThematicNodeData } =
  thematicLineagePageSlice.actions;
export default thematicLineagePageSlice.reducer;
