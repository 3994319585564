import { Result } from 'shared/core/Result';
import { regulatoryLineageURLArray } from 'shared/helpers/constants';
import { httpClient } from 'shared/utils/httpClient';

import { IRegulatoryLineageRequests } from './IRegulatoryLineageRequests';

export class RegulatoryLineageRequests implements IRegulatoryLineageRequests {
  async getRegulatoryData(columnId: number, nodeId: number, reqData: any): Promise<Result<any>> {
    try {
      const response = await httpClient.post<any>(regulatoryLineageURLArray[columnId], reqData);
      if (response.status == 200) {
        const data = response.data;
        return Result.ok<any>(data);
      } else {
        return Result.fail<any>(response.data?.error || 'No data found');
      }
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }
}
