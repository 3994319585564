import clsx from 'clsx';
import { mandatoryLogoutTimer, msalInstance } from 'msalConfig';
import React, { useCallback, useEffect, useRef } from 'react';

import Header from './header/header.component';
import styles from './pageLayout.module.scss';
import SideNav from './sideNav/sideNav.component';

interface LayoutProps {
  children: React.ReactNode;
  toggleSideNavBar: any;
  isSideNavCollapsed: boolean;
  setIsSideNavCollapsed: any;
}

const PageLayout = ({
  children,
  toggleSideNavBar,
  isSideNavCollapsed,
  setIsSideNavCollapsed
}: LayoutProps): JSX.Element => {
  const onSessionTimeout = () => {
    msalInstance.logoutRedirect();
  };

  const activityTimer = useRef<NodeJS.Timeout>();
  const lastActivityTime = useRef<number>(Date.now());

  const resetTimer = useCallback(() => {
    lastActivityTime.current = Date.now();
    if (activityTimer.current) {
      clearTimeout(activityTimer.current);
    }
    activityTimer.current = setTimeout(() => {
      onSessionTimeout();
    }, mandatoryLogoutTimer); // Convert minutes to milliseconds
  }, [mandatoryLogoutTimer, onSessionTimeout]);

  useEffect(() => {
    // Set up event listeners for user activity
    const handleUserActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    // Set the initial timer
    resetTimer();

    // Clean up event listeners and timer on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      if (activityTimer.current) {
        clearTimeout(activityTimer.current);
      }
    };
  }, [resetTimer]);

  return (
    <>
      <Header toggleSideNavBar={toggleSideNavBar} />
      <div className={clsx(styles.baseRouteHeaderContentWrapper)}>
        <SideNav isCollapsed={isSideNavCollapsed} setIsSideNavCollapsed={setIsSideNavCollapsed} />
        <div
          className={clsx(styles.mainContainer, 'motif-no-gutter', 'pageContainerColor')}
          style={{ marginLeft: isSideNavCollapsed ? '52px' : '284px' }}>
          {children}
        </div>
      </div>
    </>
  );
};
export default PageLayout;
