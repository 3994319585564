import {
  MotifButton,
  MotifCard,
  MotifCardBody,
  MotifIcon,
  MotifLabel,
  MotifOption,
  MotifProgressLoader,
  MotifSelect
} from '@ey-xd/motif-react';
import clsx from 'clsx';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import { DataSourcingPageIcon } from 'images/dataSouringPageIcon';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import {
  fetchDataSourceGridData,
  fetchDataSourceGridDownload,
  fetchDisclosures,
  fetchDomains,
  fetchScenarios,
  resetData,
  setDataSourcingDisclosures,
  setDataSourcingDomains,
  setDataSourcingScenarios
} from 'shared/redux/slice/dataSourcingGridSlice';
import { selectTopic } from 'shared/redux/slice/dataSourcingPageSlice';

import notFound from '../../../../../images/404.svg';
import styles from '../../../pages/sapDataSourcingPage/sapDataSourcingPage.module.scss';
import DataSourceGrid from '../dataSourceGrid/dataSourceGrid.component';
import DataSourcingTopicTitle from '../dataSourcingTopicTitle/dataSourcingTopicTitle.component';

interface DataSourcingProps {
  isSideNavCollapsed: boolean;
}

const DataSourcingPopup = ({ isSideNavCollapsed }: DataSourcingProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedRegulation, selectedPillar, selectedTopic } = useAppStore().dataSourcingPageSlice;
  const {
    dataSourceGridData,
    domains,
    selectedDomains,
    scenarios,
    isGridLoading,
    disclosures,
    selectedDisclosures,
    selectedScenarios
  } = useAppStore().dataSourceGridSlice;

  const { userPermissions } = useAppStore().userProfileSlice;

  const [error, setError] = useState<string>('');

  const handleBack = () => {
    dispatch(selectTopic());
    dispatch(resetData());
  };

  useEffect(() => {
    dispatch(
      fetchDisclosures({
        regulationName: selectedRegulation?.name || '',
        pillarName: selectedPillar?.pillarName || '',
        topicName: selectedTopic?.id || ''
      })
    ).then((data) => {
      if (data.type.indexOf('rejected') >= 0) {
        setError('Error occured while fetching Data sourcing system details');
      }
    });
  }, [dispatch]);

  const handleDisclosureChange = (selectedValue: string[]) => {
    if (selectedValue.length > 0) {
      const disclosureDetails = disclosures?.filter((value) => {
        if (value.disclosureName) return selectedValue.includes(value.disclosureName);
      });
      dispatch(setDataSourcingDisclosures(disclosureDetails));
      if (disclosureDetails)
        dispatch(fetchDomains(disclosureDetails)).then((data) => {
          if (data.type.indexOf('rejected') >= 0) {
            setError('Error occured while fetching Data sourcing system details');
          }
        });
    } else dispatch(setDataSourcingDisclosures([]));
  };

  const handleDomainChange = (selectedValue: string[]) => {
    if (selectedValue.length > 0) {
      const domainDetails = domains?.filter((value) => {
        if (value.domainName) return selectedValue.includes(value.domainName);
      });
      dispatch(setDataSourcingDomains(domainDetails));
      if (domainDetails)
        dispatch(fetchScenarios(domainDetails)).then((data) => {
          if (data.type.indexOf('rejected') >= 0) {
            setError('Error occured while fetching Data sourcing system details');
          }
        });
    } else dispatch(setDataSourcingDomains([]));
  };

  const handleScenarioChange = (selectedValue: string[]) => {
    if (selectedValue.length > 0) {
      const scenarioDetails = scenarios?.filter((value) => {
        if (value.scenarioName) return selectedValue.includes(value.scenarioName);
      });
      dispatch(setDataSourcingScenarios(scenarioDetails));
      if (scenarioDetails)
        dispatch(fetchDataSourceGridData(scenarioDetails)).then((data) => {
          if (data.type.indexOf('rejected') >= 0) {
            setError('Error occured while fetching Data sourcing system details');
          }
        });
    } else dispatch(setDataSourcingScenarios([]));
  };

  const handleErrorModalClose = () => {
    setError('');
  };

  return (
    <div className="motif-container">
      <MotifProgressLoader show={isGridLoading} fullscreen />
      <div className="widgetSpacing">
        <div onClick={handleBack} className="back-button motif-body2-default-regular">
          <MotifIcon src={`data:image/svg+xml;base64,${DataSourcingPageIcon.ArrowLeftSvg}`} />
          <span>Back</span>
        </div>
      </div>
      <div className="widgetSpacing">
        <DataSourcingTopicTitle name={selectedTopic?.name} size="large" />
      </div>
      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className="motif-col-lg-11">
          <div
            className={
              isSideNavCollapsed
                ? clsx('motif-row', styles.popupDropdownWithoutSideNav)
                : clsx('motif-row', styles.popupDropdownWithSideNav)
            }>
            <div className="motif-col-lg-4">
              {disclosures && (
                <>
                  <MotifLabel className={styles.selectboxLabel}>Disclosure</MotifLabel>
                  <MotifSelect
                    multiple
                    filter
                    className={styles.selectbox}
                    placeholder="Please select Disclosure"
                    value={selectedDisclosures?.map((data) => {
                      return data.disclosureName;
                    })}
                    onChange={handleDisclosureChange}>
                    {disclosures?.map((item) => (
                      <MotifOption key={item.disclosureName} value={item.disclosureName}>
                        {item.disclosureName}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </>
              )}
            </div>
            <div className="motif-col-lg-4">
              {domains && domains.length > 0 && (
                <>
                  <MotifLabel className={styles.selectboxLabel}>Domain</MotifLabel>
                  <MotifSelect
                    multiple
                    filter
                    className={styles.selectbox}
                    placeholder="Please select Domain"
                    value={selectedDomains?.map((data) => {
                      return data.domainName;
                    })}
                    onChange={handleDomainChange}>
                    {domains?.map((item) => (
                      <MotifOption
                        key={`${item.domainName}.${Math.random() * 100}`}
                        value={item.domainName}>
                        {item.domainName}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </>
              )}
            </div>
            <div className="motif-col-lg-4">
              {scenarios && scenarios.length > 0 && (
                <>
                  <MotifLabel className={styles.selectboxLabel}>Scenario</MotifLabel>
                  <MotifSelect
                    multiple
                    filter
                    className={`${styles.selectbox} no-option-height`}
                    placeholder="Please select Scenario"
                    value={selectedScenarios?.map((value) => {
                      return value.scenarioName;
                    })}
                    onChange={handleScenarioChange}>
                    {scenarios?.map((item) => (
                      <MotifOption
                        key={`${item.scenarioId}.${Math.random() * 100}`}
                        value={item.scenarioName}>
                        {item.scenarioName}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('widgetSpacing')}>
        {selectedDisclosures &&
        selectedDisclosures.length > 0 &&
        selectedDomains &&
        selectedDomains.length > 0 &&
        selectedScenarios &&
        selectedScenarios.length > 0 &&
        dataSourceGridData ? (
          <MotifCard
            className={
              isSideNavCollapsed ? styles.dataGridHeaderCard : styles.dataGridHeaderCardWithSideNav
            }>
            <MotifCardBody className={styles.dataGridHeader}>
              <div>
                <div className="motif-subtitle-default-regular">ESG Regulation Grid View</div>
                <div className={`motif-body2-default-regular ${styles.dataGridHeaderSubtitle}`}>
                  Descriptive text that describes your page or content.
                </div>
              </div>
              {userPermissions &&
                userPermissions.length > 0 &&
                userPermissions.filter(
                  (permission) =>
                    permission.type == 'FrontendPermission' &&
                    permission.value == 'CanDownloadFromSAPDataSourcingPage'
                ).length > 0 && (
                  <MotifButton
                    variant="text"
                    className={styles.dataGridDownload}
                    onClick={() => {
                      dispatch(fetchDataSourceGridDownload(selectedScenarios));
                    }}>
                    <span className="motif-body2-default-regular">Download Excel</span>
                    <MotifIcon
                      src={`data:image/svg+xml;base64,${DataSourcingPageIcon.DownloadSvg}`}
                    />
                  </MotifButton>
                )}
            </MotifCardBody>
            <DataSourceGrid data={dataSourceGridData} />
          </MotifCard>
        ) : (
          <div className="emptyGridData">
            <img src={notFound} alt="" />
            <div className="motif-body1-default-regular">No Data to Display</div>
            <div className="motif-body2-default-regular	emptyGridData_subTitle">
              Please select your a sub domains and scenario to view the list of ESG regulations in
              grid view...
            </div>
          </div>
        )}
      </div>
      {error.length > 0 && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}
    </div>
  );
};

export default DataSourcingPopup;
