import {
  MotifButton,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader
} from '@ey-xd/motif-react';
import clsx from 'clsx';
import RoundIconContainer from 'components/pageContents/atoms/roundIconContainer/roundIconContainer.component';
import { CommonIcons } from 'images/commonIcons';
import React from 'react';

import styles from './errorModalWindow.module.scss';

interface ModalProps {
  isModalOpen: boolean;
  handleModalClose: any;
}

const ErrorModelWindow = ({ isModalOpen, handleModalClose }: ModalProps): JSX.Element => {
  const popupHeading = 'Error Occurred';
  const closeButtonProps = {
    'aria-label': 'Close',
    title: 'Close'
  };

  const roundCustomStyle = {
    backgroundColor: '#2E2E38'
  };
  return (
    <MotifModal
      onClose={handleModalClose}
      variant="alt"
      className={styles.modal}
      show={isModalOpen}>
      <MotifModalHeader closeButtonProps={closeButtonProps} className={styles.modalHeaderBox}>
        <div className={styles.modalHeaderBoxInner}>
          <div>
            <RoundIconContainer customStyle={roundCustomStyle} icon={CommonIcons.errorWarningSvg} />
          </div>
          <span className={clsx(styles.headingText, styles.removePadding)}>{popupHeading}</span>
        </div>
      </MotifModalHeader>
      <MotifModalBody className={styles.contentPadding}>
        <React.Fragment key=".0">
          Please try again later, and if the error persists, contact the admin/support team for
          assistance.
        </React.Fragment>
      </MotifModalBody>
      <MotifModalFooter className={clsx(styles.footer)}>
        <React.Fragment key=".0">
          <MotifButton
            onClick={handleModalClose}
            className={styles.modalCloseButton}
            size="large"
            type="button"
            variant="primary-alt">
            Close
          </MotifButton>
        </React.Fragment>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default ErrorModelWindow;
