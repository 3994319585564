import type { SankeyExtraProperties, SankeyNode } from 'd3-sankey';
import { ExtraNodeProperties } from 'shared/models/Sankey';

export const InitialRegulatoryNodeData: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[] = [
  { name: 'Reporting Framework', levelX: 0, levelY: 0 },
  { name: 'Sustainability Pillar', levelX: 1, levelY: 0 },
  { name: 'Reporting Framework Section', levelX: 2, levelY: 0 },
  { name: 'Reporting Framework Sub-Section', levelX: 3, levelY: 0 },
  { name: 'Regulatory Text', levelX: 4, levelY: 0, searchBox: true },
  { name: 'Obligation Text', levelX: 5, levelY: 0, searchBox: true },
  { name: 'Primary Disclosures', levelX: 6, levelY: 0, searchBox: true },
  { name: 'Intermediate Attributes', levelX: 7, levelY: 0, searchBox: true },
  { name: 'Core Data Attributes', levelX: 8, levelY: 0, searchBox: true }
];

export const InitialThematicNodeData: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[] = [
  { name: 'Sustainability Pillar', levelX: 0, levelY: 0 },
  { name: 'Theme', levelX: 1, levelY: 0 },
  { name: 'Reporting Framework Section', levelX: 2, levelY: 0 },
  { name: 'Reporting Framework Sub-Section', levelX: 3, levelY: 0 },
  {
    name: 'Obligation Text',
    levelX: 4,
    levelY: 0,
    searchBox: true,
    isFilter: true,
    filterOptions: ['CSRD', 'ISSB', 'TCFD', 'SFDR', 'PCAF'],
    info: 'To view Obligation Text of specific ESG Regulations and Frameworks, please make your selection through the filter button. \n To preview and extract data for a specific Obligation Text, please click on the respective text box and then click the Extract Data button.',
    showDisableFilterTooltip: false
  },
  {
    name: 'Primary Disclosures',
    levelX: 5,
    levelY: 0,
    searchBox: true
  },
  { name: 'Intermediate Attributes', levelX: 6, levelY: 0, searchBox: true },
  { name: 'Core Data Attributes', levelX: 7, levelY: 0, searchBox: true }
];

export const InitialSapNodeData: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[] = [
  { name: 'Sustainability Pillar', levelX: 0, levelY: 0 },
  { name: 'Reporting Framework Section', levelX: 1, levelY: 0 },
  { name: 'Reporting Framework Sub-Section', levelX: 2, levelY: 0 },
  { name: 'Obligation Text', levelX: 3, levelY: 0, searchBox: true },
  { name: 'Primary Disclosures', levelX: 4, levelY: 0, searchBox: true },
  { name: 'General Calculations', levelX: 5, levelY: 0, searchBox: true },
  { name: 'Scenario', levelX: 6, levelY: 0, searchBox: true },
  { name: 'Logic', levelX: 7, levelY: 0, searchBox: true },
  { name: 'Field Mapping', levelX: 8, levelY: 0, searchBox: true },
  { name: 'Module', levelX: 9, levelY: 0, searchBox: true },
  { name: 'Table', levelX: 10, levelY: 0, searchBox: true },
  { name: 'Field', levelX: 11, levelY: 0, searchBox: true }
];
