import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SankeyExtraProperties, SankeyNode } from 'd3-sankey';
import {
  SAP_LINEAGE_MAX_LEVEL_X,
  sapLineageRequestData,
  sapLineageURLArray
} from 'shared/helpers/constants';
import { ExtraNodeProperties } from 'shared/models/Sankey';
import { ISapLineagePageData } from 'shared/models/sapHomePageModel';
import { ITopWidgets } from 'shared/models/sapLineagePageModel';
import httpClient from 'shared/utils/httpClient';
import { processSapRegulatoryData } from 'shared/utils/utils';

import { ResponseError } from '../../core/IError';

interface ISapLineagePageSliceState {
  sapLineagePageData: ISapLineagePageData;
  pending: boolean;
  error?: string;
  metricCount: ITopWidgets;
}

const initialState: ISapLineagePageSliceState = {
  metricCount: {
    primary: 0,
    scenario: 0
  },
  sapLineagePageData: {},
  pending: true,
  error: ''
};

interface IRequestParams {
  columnId: number;
  nodeId: number;
  parentKey: string;
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
}

const createRequestData = (
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
  columnId: number
) => {
  if (columnId == SAP_LINEAGE_MAX_LEVEL_X + 1) {
    const data = {
      obligationNode: selectedNodes.find((value) => {
        return value.levelX == 5;
      })?.key,
      coreAttributeNode: selectedNodes.find((value) => {
        return value.levelX == SAP_LINEAGE_MAX_LEVEL_X;
      })?.key
    };
    return data;
  } else {
    const data: any = {};
    for (let i = 0; i < columnId; i++) {
      if (sapLineageRequestData.length > i) {
        const node = selectedNodes.find((value) => {
          return value.levelX == i;
        });
        if (node) data[sapLineageRequestData[i]] = node.key;
      }
    }
    return data;
  }
};

export const fetchSapLineageData = createAsyncThunk<
  any,
  IRequestParams,
  { rejectValue: ResponseError }
>(
  'sap/fetchSapLineageData',
  async ({ columnId, parentKey, selectedNodes }: IRequestParams, thunkApi) => {
    const reqData = createRequestData(selectedNodes, columnId);
    if (columnId < SAP_LINEAGE_MAX_LEVEL_X + 1) {
      return httpClient
        .post(sapLineageURLArray[columnId], reqData)
        .then(({ data }) => {
          if (columnId == SAP_LINEAGE_MAX_LEVEL_X + 1) return data;
          const {
            sapLineagePageSlice: { sapLineagePageData }
          }: any = thunkApi.getState();
          const finalData = processSapRegulatoryData(data.items, columnId, parentKey);
          return { ...sapLineagePageData, [columnId]: finalData };
        })
        .catch((err) => {
          return thunkApi.rejectWithValue({ message: err.errorValue().toString() });
        });
    } else {
      return {};
    }
  }
);

const sapLineagePageSlice = createSlice({
  name: 'sapLineagePageSlice',
  initialState,
  reducers: {
    updateSapMetricCount: (
      state: ISapLineagePageSliceState,
      action: PayloadAction<ITopWidgets>
    ) => {
      state.metricCount = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSapLineageData.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchSapLineageData.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(fetchSapLineageData.fulfilled, (state, action) => {
        if (action.payload && action.meta.arg.columnId == 0) {
          state.pending = false;
          state.sapLineagePageData = action.payload;
        }
      });
  }
});

export const { updateSapMetricCount } = sapLineagePageSlice.actions;
export default sapLineagePageSlice.reducer;
