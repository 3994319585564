import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dataSourcingApi } from 'shared/helpers/constants';
import { httpClient } from 'shared/utils/httpClient';

export interface IPillarDetails {
  pillarName: string;
  topics: { name: string; id: string }[];
}

export interface IRegulationDetails {
  name: string;
  pillars: IPillarDetails[];
}

interface ISelectPillar {
  name: string;
  key: number;
}

interface ISelectTopic {
  name: string;
  id: string;
}

interface IDataSourcingSystemDetails {
  dataSourcingName: string;
  regulations: IRegulationDetails[];
}

interface IDataSourcingPageSliceState {
  dataSourcingList: string[];
  selectedDataSource?: IDataSourcingSystemDetails;
  regulationList: string[];
  selectedRegulation?: IRegulationDetails;
  regulationDetails?: IRegulationDetails[];
  dataSourcingSystemDetails?: IDataSourcingSystemDetails[];
  selectedPillar?: IPillarDetails;
  selectedTopic?: ISelectTopic;
  isRegulationLoading?: boolean;
}

export const fetchDataSourcingDetails = createAsyncThunk<IDataSourcingSystemDetails[]>(
  'dataSourcingPageSlice/fetchRegulationList',
  async () => {
    return httpClient.get(dataSourcingApi.dataSourcing).then(({ data }) => {
      return data as IDataSourcingSystemDetails[];
    });
  }
);

const initialState: IDataSourcingPageSliceState = {
  dataSourcingList: [],
  regulationList: []
};

const dataSourcingPageSlice = createSlice({
  name: 'dataSourcingPageSlice',
  initialState,
  reducers: {
    selectDataSource: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload)
        state.selectedDataSource = state.dataSourcingSystemDetails?.find((data) => {
          return data.dataSourcingName == action.payload;
        });
    },
    selectRegulation: (state, action: PayloadAction<string | undefined>) => {
      if (state.selectedDataSource) {
        state.selectedRegulation = state.selectedDataSource.regulations?.find((data) => {
          return data.name == action.payload;
        });
        state.regulationDetails = state.selectedDataSource.regulations;
        state.selectedPillar = state.selectedRegulation?.pillars[0];
      }
    },
    selectPillar: (state, action: PayloadAction<ISelectPillar | undefined>) => {
      if (state.selectedRegulation)
        state.selectedPillar = state.selectedRegulation.pillars?.find((data) => {
          return data.pillarName == action.payload?.name;
        });
    },
    selectTopic: (state, action: PayloadAction<ISelectTopic | undefined>) => {
      state.selectedTopic = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchDataSourcingDetails.fulfilled, (state, action) => {
      state.dataSourcingList = [];
      state.dataSourcingSystemDetails = action.payload;
      action.payload.map((sourcingSystem) => {
        state.dataSourcingList.push(sourcingSystem.dataSourcingName);
      });
      state.selectedDataSource = action.payload[0];
      state.regulationList = action.payload[0].regulations.map((regulation) => {
        return regulation.name;
      });
      state.isRegulationLoading = false;
    });
    builder.addCase(fetchDataSourcingDetails.rejected, (state) => {
      state.dataSourcingSystemDetails = [];
      state.regulationList = [];
      state.isRegulationLoading = false;
    });
    builder.addCase(fetchDataSourcingDetails.pending, (state) => {
      state.isRegulationLoading = true;
    });
  }
});

export const { selectDataSource, selectRegulation, selectPillar, selectTopic } =
  dataSourcingPageSlice.actions;

export default dataSourcingPageSlice.reducer;
