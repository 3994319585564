import { MotifTruncate } from '@ey-xd/motif-react';
import { ThemeProvider, createTheme } from '@mui/material';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import React, { useMemo } from 'react';
import {
  DataSourceGridDataProps,
  DataSourceGridSubDataProps
} from 'shared/redux/slice/dataSourcingGridSlice';

interface DataSourceGridProps {
  data?: DataSourceGridDataProps[];
}

const ellipsisText = (value: string) => {
  return (
    <MotifTruncate
      maxLines={2}
      className="dataGridCellValue"
      tooltipProps={{ contentClassName: 'tooltipContent' }}>
      {value}
    </MotifTruncate>
  );
};

const DataSourceGrid: React.FC<DataSourceGridProps> = ({ data = [] }) => {
  const columnDef = useMemo<MRT_ColumnDef<DataSourceGridDataProps>[]>(
    () => [
      {
        accessorFn: (row) => ellipsisText(row.scenario),
        header: 'Scenario',
        size: 200,
        filterFn: (row, id, filterValue) => {
          if (row.original.scenario.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            return true;
          else return false;
        }
      },
      {
        accessorFn: (row) => ellipsisText(row.primaryDisclosureMetric),
        header: 'Primary Disclosure',
        size: 300,
        filterFn: (row, id, filterValue) => {
          if (
            row.original.primaryDisclosureMetric.toLowerCase().indexOf(filterValue.toLowerCase()) >=
            0
          )
            return true;
          else return false;
        }
      },
      {
        accessorFn: (row) => ellipsisText(row.calculation),
        header: 'Calculation',
        size: 300,
        filterFn: (row, id, filterValue) => {
          if (row.original.calculation.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            return true;
          else return false;
        }
      },
      {
        accessorFn: (row) => ellipsisText(row.logic),
        header: 'Logic',
        size: 300,
        filterFn: (row, id, filterValue) => {
          if (row.original.logic.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) return true;
          else return false;
        }
      },
      {
        accessorFn: (row) => ellipsisText(row.fieldMapping),
        header: 'Field Mapping',
        size: 300,
        filterFn: (row, id, filterValue) => {
          if (row.original.fieldMapping.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            return true;
          else return false;
        }
      }
    ],
    []
  );

  const subColumnDef = useMemo<MRT_ColumnDef<DataSourceGridSubDataProps>[]>(() => {
    return [
      { accessorKey: 'module', header: 'Module', size: 200 },
      { accessorKey: 'field', header: 'Field', size: 200 },
      { accessorKey: 'fieldName', header: 'Field Name', size: 200 },
      { accessorKey: 'table', header: 'Table', size: 200 }
    ];
  }, []);

  return (
    <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
      <MaterialReactTable
        data={data}
        columns={columnDef}
        initialState={{ density: 'spacious' }}
        positionExpandColumn="last"
        enableColumnFilters={true}
        enableColumnResizing={true}
        renderDetailPanel={({ row }) => {
          return (
            <div className="subDatatable">
              <MaterialReactTable
                data={row.original.dataAttributes}
                columns={subColumnDef}
                enableTopToolbar={false}
                enableSorting={false}
                enableColumnActions={false}
                enablePagination={false}
                initialState={{ density: 'spacious' }}
              />
            </div>
          );
        }}
      />
    </ThemeProvider>
  );
};
export default DataSourceGrid;
