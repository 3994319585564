import { MotifCard, MotifCardBody, MotifIcon } from '@ey-xd/motif-react';
import { DataSourcingPageIcon } from 'images/dataSouringPageIcon';
import React, { FC } from 'react';

import DataSourcingTopicTitle from '../dataSourcingTopicTitle/dataSourcingTopicTitle.component';
import styles from './dataSourcingTopicCard.module.scss';

export interface DataSourcingTopicCardProps {
  name: string;
  id: string;
  onClick?: (params: Omit<DataSourcingTopicCardProps, 'onClick'>) => void;
}

const DataSourcingTopicCard: FC<DataSourcingTopicCardProps> = ({ name, id, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick({ name, id });
    }
  };
  return (
    <MotifCard className={styles.topicCard}>
      <MotifCardBody>
        <DataSourcingTopicTitle name={name} />
        <div className={`${styles.topicFooter} motif-body2-default-light`}>
          <div onClick={handleClick} className={styles.topicViewReport}>
            <span className="motif-body2-default-regular">View report</span>
            <MotifIcon
              className={styles.topicFooterIcon}
              src={`data:image/svg+xml;base64,${DataSourcingPageIcon.ArrowRightSvg}`}
            />
          </div>
        </div>
      </MotifCardBody>
    </MotifCard>
  );
};

export default DataSourcingTopicCard;
