import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MotifIcon,
  MotifVerticalNavigation,
  MotifVerticalNavigationContent,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigationSubmenu
} from '@ey-xd/motif-react';

import { DESKTOP_WIDTH, SAP_SIDE_MENU_ITEMS, SIDE_MENU_ITEMS } from 'shared/helpers/constants';
import { MenuList } from 'shared/models/SideNav';

import styles from './sideNav.module.scss';

interface SideNavProps {
  isCollapsed: boolean;
  setIsSideNavCollapsed: (isCollapsed: boolean) => void;
}

const SideNav = ({ isCollapsed, setIsSideNavCollapsed }: SideNavProps): JSX.Element => {
  const location = useLocation();
  const currentPath = location.pathname || '';
  const navigate = useNavigate();
  const SIDE_MENUS: MenuList[] = currentPath.includes('/saphome')
    ? SAP_SIDE_MENU_ITEMS
    : SIDE_MENU_ITEMS;

  const generateMenuItem = ({ menuItemName, iconName, path }: MenuList): JSX.Element => {
    return (
      <MotifVerticalNavigationMenuItem
        selected={currentPath == path}
        className={styles.ListMenu}
        key={menuItemName}
        icon={
          <MotifIcon className={styles.menuIcons} src={`data:image/svg+xml;base64,${iconName}`} />
        }
        label={menuItemName}
        onClick={() => {
          if (window.innerWidth < DESKTOP_WIDTH) {
            setIsSideNavCollapsed(true);
          }
          navigate(path);
        }}>
        {menuItemName}
      </MotifVerticalNavigationMenuItem>
    );
  };

  const generateMenuItemWithSubMenu = ({
    menuItemName,
    subMenu,
    iconName,
    path
  }: MenuList): JSX.Element => {
    return (
      <MotifVerticalNavigationSubmenu
        className={styles.ListMenu}
        key={menuItemName}
        icons={{
          expandToggle: null,
          title: <MotifIcon src={`data:image/svg+xml;base64,${iconName}`} />
        }}
        onClick={() => {
          if (path) {
            navigate(path);
          }
        }}
        title={menuItemName}>
        {subMenu &&
          subMenu.map((menuItem) => (
            <MotifVerticalNavigationMenuItem
              onClick={() => {
                if (window.innerWidth < DESKTOP_WIDTH) {
                  setIsSideNavCollapsed(true);
                }
                navigate(menuItem.path);
              }}
              selected={currentPath == path}
              key={menuItem.menuItemName}
              label={menuItem.menuItemName}>
              {menuItem.menuItemName}
            </MotifVerticalNavigationMenuItem>
          ))}
      </MotifVerticalNavigationSubmenu>
    );
  };

  return (
    <MotifVerticalNavigation collapse={isCollapsed} className={styles.SideNavWrapper}>
      <MotifVerticalNavigationContent className={styles.MotifNavContent}>
        <MotifVerticalNavigationMenu>
          {SIDE_MENUS.map((menuItem) =>
            menuItem.subMenu ? generateMenuItemWithSubMenu(menuItem) : generateMenuItem(menuItem)
          )}
        </MotifVerticalNavigationMenu>
      </MotifVerticalNavigationContent>
    </MotifVerticalNavigation>
  );
};

export default SideNav;
