import { MotifIcon, MotifOption, MotifSelect, MotifTooltip } from '@ey-xd/motif-react';
import clsx from 'clsx';
import { CommonIcons } from 'images/commonIcons';
import React from 'react';
import { SelectBox } from 'shared/models/AppModel';

import styles from './widgetHeading.module.scss';

export interface IWidgetHeadingProps {
  title: string;
  info?: {
    show?: boolean;
    tooltipContent: string[];
    tooltipPlacement?: 'auto' | 'right' | 'left' | 'top' | 'bottom';
  };
  showSelectBox?: {
    selectedValue?: any;
    data: SelectBox[];
    onchange: any;
  };
}

const WidgetHeading = ({ title, info, showSelectBox }: IWidgetHeadingProps) => {
  return (
    <div className={clsx('motif-row no-margin')}>
      <div className={clsx('motif-middle-xs')}>
        <h3 className={clsx(styles.title)}>{title}</h3>
      </div>
      <div className={clsx('motif-col-xs', 'motif-middle-xs', 'no-padding', styles.rightSide)}>
        {showSelectBox && (
          <MotifSelect
            className={styles.widgetSelector}
            ariaLabelledBy="select-label"
            value={
              showSelectBox?.selectedValue ? showSelectBox?.selectedValue : showSelectBox.data[0]
            }
            onChange={showSelectBox.onchange}
            visibleOptions={4}>
            {showSelectBox.data &&
              showSelectBox.data.map((item, i) => (
                <MotifOption key={`${item.key}-${i}`} value={item.key}>
                  {item.value}
                </MotifOption>
              ))}
          </MotifSelect>
        )}
        {info?.show && (
          <MotifTooltip
            placement={info?.tooltipPlacement || 'right'}
            contentClassName="headerToolTip"
            trigger={
              <MotifIcon
                className={styles.infoIcon}
                src={`data:image/svg+xml;base64,${CommonIcons.infoEmptySvg}`}
              />
            }>
            {info.tooltipContent.map((content, i) => (
              <div key={i} style={{ wordBreak: 'normal' }}>
                <span dangerouslySetInnerHTML={{ __html: content }} />
                <br />
              </div>
            ))}
          </MotifTooltip>
        )}
      </div>
    </div>
  );
};

export default WidgetHeading;
