import { MotifCard } from '@ey-xd/motif-react';
import clsx from 'clsx';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import { Linking, Node, Sankey } from 'components/pageContents/molecules/lineage/Sankey';
import MetricsCountWidget from 'components/pageContents/molecules/lineage/metricsCountWidget/metricsCountWidget.component';
import { SankeyExtraProperties, SankeyLink, SankeyNode } from 'd3-sankey';
import { LineagePageIcons } from 'images/lineagePageIcons';
import { useEffect, useState } from 'react';
import { SAP_LINEAGE_MAX_LEVEL_X } from 'shared/helpers/constants';
import { ExtraNodeProperties } from 'shared/models/Sankey';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import { fetchSapLineageData, updateSapMetricCount } from 'shared/redux/slice/sapLineagePageSlice';

import styles from './sapLineagePage.module.scss';

const SapLineagePage = (): JSX.Element => {
  const pageName = 'Data Sourcing Lineage';

  const MetricsTypes = {
    primaryDisclosureMetrics: 'Primary Disclosures',
    scenariosMetrics: 'Scenarios'
  };

  const dispatch = useAppDispatch();

  const [maxLevelXSelected, setMaxLevelXSelected] = useState<number>(-1);

  // Graph data used for lineage page
  const [graphData, setSelectedGraphData] = useState<{
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[];
  }>({ nodes: [{ key: '0', name: 'Sustainability Pillar', levelX: 0, levelY: 0 }], links: [] });
  // For Width and Height as per node data
  const maxLevelX = 0;
  const [maxLevelY, setMaxLevelY] = useState<number>(0);

  // Node Data from API
  const [nodeData, setNodeData] = useState<
    SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[]
  >([]);

  const [error, setError] = useState<string>('');

  const { sapLineagePageData, metricCount } = useAppStore().sapLineagePageSlice;

  useEffect(() => {
    const fetchDataThunk = async () => {
      if (Object.keys(sapLineagePageData).length == 0) {
        try {
          const result = await dispatch(
            fetchSapLineageData({
              columnId: 0,
              nodeId: 0,
              parentKey: '0',
              selectedNodes: []
            })
          );
          if (result.type.endsWith('rejected')) {
            throw new Error('Failed to fetch data');
          }
        } catch (error) {
          setError('Error Occurred');
        }
      }
      dispatch(updateSapMetricCount({ primary: 0, scenario: 0 }));
    };
    fetchDataThunk();
  }, []);

  useEffect(() => {
    if (Object.keys(sapLineagePageData).length > 0) {
      const mutableObject = JSON.parse(JSON.stringify(sapLineagePageData[0]));
      setNodeData(mutableObject);
      const zeroLevelNodes = mutableObject.filter((node: any) => {
        return node.levelX == 0;
      });
      setSelectedGraphData({ nodes: zeroLevelNodes, links: [] });
      let levelX = 0;
      let levelY = 0;
      mutableObject.map((node: any) => {
        levelX = node.levelX > levelX ? node.levelX : levelX;
        levelY = node.levelY > levelY ? node.levelY : levelY;
      });
      setMaxLevelY(levelY);
    }
  }, [Object.keys(sapLineagePageData).length]);

  const onNodeClick = async (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[],
    currentNode: any,
    levelX: number
  ) => {
    setMaxLevelXSelected(currentNode.levelX);
    if (currentNode?.extraData?.count) {
      dispatch(
        updateSapMetricCount({
          primary: Number(currentNode?.extraData?.count?.primaryCount || 0),
          scenario: Number(currentNode?.extraData?.count?.scenarioCount || 0)
        })
      );
    }
    let inComingNodes: any = [];
    let nextLevelData: any = [];
    try {
      nextLevelData = await dispatch(
        fetchSapLineageData({
          columnId: currentNode.levelX + 1,
          nodeId: currentNode.key,
          parentKey: currentNode.key,
          selectedNodes: nodes.filter((value) => {
            return value.isSelected;
          })
        })
      );
      const { payload } = nextLevelData;
      if (Object.keys(payload).length > 0) {
        inComingNodes = JSON.parse(JSON.stringify(payload[currentNode.levelX + 1]));
        const nextLevelNode = inComingNodes.filter((node: any) => {
          return node.levelX == levelX + 1;
        });
        let filteredNode = [...nodeData];
        const nextLevelNodeCheck = nodeData.findIndex((value) => {
          return value.levelX == levelX + 1;
        });
        if (nextLevelNodeCheck >= 0) {
          filteredNode = nodeData.filter((value) => {
            return value.levelX != levelX + 1;
          });
        }
        const fullNode = [...filteredNode, ...inComingNodes];
        let levelNX = 0;
        let levelNY = 0;
        fullNode.map((node: any) => {
          levelNX = node.levelX > levelNX ? node.levelX : levelNX;
          levelNY = node.levelY > levelNY ? node.levelY : levelNY;
        });
        setMaxLevelY(levelNY);
        setNodeData(fullNode);
        const newNodeToDraw = [...nodes, ...nextLevelNode];
        await setSelectedGraphData({
          nodes: newNodeToDraw,
          links: links
        });
      } else {
        const latestNodes = nodes.filter((data) => {
          return data.levelX <= currentNode.levelX;
        });
        const newNodeToDraw = [...latestNodes];
        const latestLink = links.filter((data: any) => {
          return (
            typeof data.source == 'number' ||
            typeof data.target == 'number' ||
            data.source.levelX <= currentNode.levelX - 1
          );
        });
        await setSelectedGraphData({
          nodes: newNodeToDraw,
          links: latestLink
        });
      }
    } catch (error) {
      const latestNodes = nodes.filter((data) => {
        return data.levelX <= currentNode.levelX;
      });
      const newNodeToDraw = [...latestNodes];
      const latestLink = links.filter((data: any) => {
        return (
          typeof data.source == 'number' ||
          typeof data.target == 'number' ||
          data.source.levelX <= currentNode.levelX - 1
        );
      });
      await setSelectedGraphData({
        nodes: newNodeToDraw,
        links: latestLink
      });
      setError('Error Occurred');
    }
  };

  const updateSearchResult = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const updateNodeExpansion = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const handleErrorModalClose = () => {
    setError('');
  };

  return (
    <>
      <div className={clsx('motif-container', styles.borderBottom)}>
        <div className={clsx('widgetSpacing', 'motif-row')}>
          <div className="pageHeading">
            <h2>{pageName}</h2>
          </div>
        </div>

        <div className={clsx('widgetSpacing', 'motif-row')} style={{ paddingBottom: '15px' }}>
          <div
            className={clsx('motif-col-lg-6', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
              <MetricsCountWidget
                count={metricCount.primary}
                metricsName={MetricsTypes.primaryDisclosureMetrics}
                icon={LineagePageIcons.onePointCircleSvg}
              />
            </MotifCard>
          </div>
          <div
            className={clsx('motif-col-lg-6', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
              <MetricsCountWidget
                count={metricCount.scenario}
                metricsName={MetricsTypes.scenariosMetrics}
                icon={LineagePageIcons.twoPointsCircleSvg}
              />
            </MotifCard>
          </div>
        </div>
      </div>
      <div className="motif-container" style={{ display: 'grid' }}>
        <div className={clsx('widgetSpacing', 'motif-row')} style={{ overflowX: 'auto' }}>
          {graphData && (
            <Sankey<ExtraNodeProperties, SankeyExtraProperties>
              data={graphData}
              nodeWidth={150}
              height={110 + maxLevelY * 80}
              width={200 * (SAP_LINEAGE_MAX_LEVEL_X + 1) + 100 * SAP_LINEAGE_MAX_LEVEL_X}
              nodePadding={40}>
              {({ graph }) => {
                return (
                  <g>
                    {graph.links &&
                      graph.links.map((link: any, i) => (
                        <Linking
                          key={`sankey-link-${i}`}
                          link={link}
                          color={'#DA5BB3'}
                          maxWidth={30}
                          graph={graph}
                        />
                      ))}
                    {graph &&
                      graph.nodes.map((node, i) => {
                        return (
                          <Node<ExtraNodeProperties, SankeyExtraProperties>
                            key={`sankey-node-${i}`}
                            index={i}
                            currentNode={node}
                            height={60}
                            width={200}
                            graph={graph}
                            maxLevelXSelected={maxLevelXSelected}
                            apiNodeData={nodeData}
                            maxLevelX={maxLevelX}
                            onNodeClicked={onNodeClick}
                            updateSearchOnView={updateSearchResult}
                            updateNodeExpansionOnView={updateNodeExpansion}
                          />
                        );
                      })}
                  </g>
                );
              }}
            </Sankey>
          )}
        </div>
      </div>
      {error && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}
    </>
  );
};
export default SapLineagePage;
