import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PillarColorMap, rootData } from 'shared/data/dataCategoryPage';
import { dataCategoryApi } from 'shared/helpers/constants';
import { httpClientDotNet } from 'shared/utils/httpClient';

import { RootState } from '../store';

export interface DataCategoryGridData {
  pillar: string;
  theme: string;
  topic: string;
  subTopic: string;
  primaryDisclosureMetrics: string;
  constituentMetrics: string;
  coreData: string;
  csrd: boolean;
  issb: boolean;
  tcfd: boolean;
  sfdr: boolean;
  pcaf: boolean;
}

export interface DataCategoryGridResponse {
  pillar: string;
  theme: string;
  topic: string;
  subTopic: string;
  primaryDisclosureMetrics: string;
  constituentMetrics: string;
  coreData: string;
  regulation?: string;
  columnId: number;
}

const convertDataToGridData = (data: DataCategoryGridResponse[]) => {
  const result: Record<string, DataCategoryGridData> = {};

  data.map((item) => {
    const id = [item.primaryDisclosureMetrics, item.constituentMetrics, item.coreData].join('-');
    const regulation = {
      csrd: false,
      issb: false,
      tcfd: false,
      sfdr: false,
      pcaf: false
    };
    if (item.regulation)
      switch (item.regulation.toLowerCase()) {
        case 'csrd':
          regulation.csrd = true;
          break;
        case 'tcfd':
          regulation.tcfd = true;
          break;
        case 'pcaf':
          regulation.pcaf = true;
          break;
      }
    delete item.regulation;

    if (!result[id]) {
      result[id] = {
        ...item,
        ...regulation
      };
    }
  });

  return {
    gridData: Object.values(result)
  };
};

export interface TreeNode {
  key: string;
  type: 'node';
  value: number;
  name: string;
  color: string;
  isSelect: boolean;
  children: TreeLeaf[];
}
export interface TreeLeaf {
  key: string;
  type: 'leaf';
  name: string;
  value: number;
  color: string;
  isSelect: boolean;
  counts: IRegulationCount[];
}

export type Tree = TreeLeaf | TreeNode;

interface ICountValues {
  countValue: number;
  csrd?: number;
  issb?: number;
  sfdr?: number;
  pcaf?: number;
  tcfd?: number;
}

interface IRegulationCount {
  regulationName: string;
  value: number;
}
export interface IDataItem {
  nodeId: string;
  value: string;
  totalCount: number;
  counts: IRegulationCount[];
  columnId: number;
}

interface IDataCategoryPageSliceState {
  pillarsTreemapData: Tree;
  selectedPillar?: TreeLeaf;
  themesTreemapData: Tree;
  selectedTheme?: TreeLeaf;
  topicsTreemapData: Tree;
  selectedTopic?: TreeLeaf;
  subTopicsTreemapData: Tree;
  selectedSubTopic?: TreeLeaf;
  isPillarLoading: boolean;
  countValue: ICountValues;
  dataCategoryGridDataResponse?: DataCategoryGridData[];
  dataCategoryGridData?: DataCategoryGridData[];
  isGridLoading?: boolean;
  topWidgetRegulations: IRegulationCount[];
  selectedRegulations: string[];
}

export const convertAPIDataToTreemapData = (
  apiData: IDataItem[],
  level: string,
  selectedPillar?: string
) => {
  const treemapData: TreeNode = { ...rootData };
  treemapData.children = apiData.map((dataSet) => {
    const color =
      level != 'level0'
        ? PillarColorMap.get(`${level}-${selectedPillar}`)
        : PillarColorMap.get(`${level}-${dataSet.value}`);
    return {
      key: dataSet.nodeId,
      type: 'leaf',
      name: dataSet.value,
      isSelect: false,
      value: dataSet.totalCount,
      color: color ? color : '#C2ABE3',
      counts: dataSet.counts
    };
  });
  return {
    ...treemapData
  };
};

interface RequestData {
  columnId: number;
  body: {
    pillarNode?: string;
    themeNode?: string;
    topicNode?: string;
    subTopicNode?: string;
  };
}

interface IDataCategoryDetailResponse {
  totalCount: number;
  regulations: [];
  pillars?: IDataItem[];
  items?: IDataItem[];
}

export const fetchDataCategoryData = createAsyncThunk<
  IDataCategoryDetailResponse,
  RequestData,
  { state: RootState }
>('dataCategoryPageSlice/fecthDataCategoryData', async (reqData) => {
  return httpClientDotNet.post(dataCategoryApi[reqData.columnId], reqData.body).then(({ data }) => {
    return data;
  });
});

export const fetchDataCategoryGrid = createAsyncThunk<
  DataCategoryGridResponse[],
  RequestData,
  { state: RootState }
>('dataCategoryPageSlice/fecthDataCategoryGrid', async (reqData) => {
  return httpClientDotNet.post(dataCategoryApi[reqData.columnId], reqData.body).then(({ data }) => {
    return data;
  });
});

export const fetchDataCategoryGridDownload = createAsyncThunk<
  any,
  RequestData,
  { state: RootState }
>('dataCategoryPageSlice/fecthDataCategoryGridDownload', async (reqData) => {
  return httpClientDotNet
    .post(dataCategoryApi[reqData.columnId], reqData.body, {
      responseType: 'blob'
    })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data_category_excel.xlsx');
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
      return data;
    });
});

const initialState: IDataCategoryPageSliceState = {
  countValue: { countValue: 0 },
  pillarsTreemapData: rootData,
  themesTreemapData: rootData,
  topicsTreemapData: rootData,
  subTopicsTreemapData: rootData,
  topWidgetRegulations: [],
  isPillarLoading: true,
  selectedRegulations: [],
  dataCategoryGridDataResponse: []
};

const dataCategoryPageSlice = createSlice({
  name: 'dataCategoryPageSlice',
  initialState,
  reducers: {
    selectPillar: (state, action: PayloadAction<TreeLeaf | undefined>) => {
      state.selectedPillar = action.payload;
      state.topWidgetRegulations.map((topRegulation) => {
        action.payload?.counts.map((regulation) => {
          if (topRegulation.regulationName == regulation.regulationName)
            topRegulation.value = regulation.value;
        });
      });
      state.selectedRegulations = [];
    },
    selectTheme: (state, action: PayloadAction<TreeLeaf | undefined>) => {
      state.selectedTheme = action.payload;
      state.topWidgetRegulations.map((topRegulation) => {
        action.payload?.counts.map((regulation) => {
          if (topRegulation.regulationName == regulation.regulationName)
            topRegulation.value = regulation.value;
        });
      });
      state.selectedRegulations = [];
    },
    selectTopic: (state, action: PayloadAction<TreeLeaf | undefined>) => {
      state.selectedTopic = action.payload;
      state.topWidgetRegulations.map((topRegulation) => {
        action.payload?.counts.map((regulation) => {
          if (topRegulation.regulationName == regulation.regulationName)
            topRegulation.value = regulation.value;
        });
      });
      state.selectedRegulations = [];
    },
    selectSubTopic: (state, action: PayloadAction<TreeLeaf | undefined>) => {
      state.selectedSubTopic = action.payload;
      state.topWidgetRegulations.map((topRegulation) => {
        action.payload?.counts.map((regulation) => {
          if (topRegulation.regulationName == regulation.regulationName)
            topRegulation.value = regulation.value;
        });
      });
      state.selectedRegulations = [];
    },
    setPillarsList: (state, action: PayloadAction<TreeNode | TreeLeaf>) => {
      state.pillarsTreemapData = action.payload;
    },
    setThemesList: (state, action: PayloadAction<TreeNode | TreeLeaf>) => {
      state.themesTreemapData = action.payload;
    },
    setTopicsList: (state, action: PayloadAction<TreeNode | TreeLeaf>) => {
      state.topicsTreemapData = action.payload;
    },
    setSubTopicsList: (state, action: PayloadAction<TreeNode | TreeLeaf>) => {
      state.subTopicsTreemapData = action.payload;
    },
    setCountValue: (state) => {
      state.countValue = { countValue: 0 };
    },
    setSelectedRegulations: (state, action: PayloadAction<string[]>) => {
      state.selectedRegulations = action.payload;
      if (state.selectedRegulations.length == 0) {
        if (
          state.selectedPillar &&
          state.selectedTheme &&
          state.selectedTopic &&
          state.selectedSubTopic &&
          state.dataCategoryGridData
        ) {
          state.selectedRegulations = action.payload;
          state.dataCategoryGridData = state.dataCategoryGridDataResponse;
        }
      } else {
        let filteredData: DataCategoryGridData[] = [];
        state.selectedRegulations.map((regulation) => {
          if (
            state.selectedPillar &&
            state.selectedTheme &&
            state.selectedTopic &&
            state.selectedSubTopic &&
            state.dataCategoryGridDataResponse
          ) {
            filteredData = filteredData.concat(
              state.dataCategoryGridDataResponse.filter((item) => {
                return (item as any)[`${regulation.toLowerCase()}`];
              })
            );
          }
        });
        state.dataCategoryGridData = filteredData;
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchDataCategoryData.fulfilled, (state, action) => {
      switch (action.meta.arg.columnId) {
        case 0:
          state.topWidgetRegulations = action.payload.regulations;
          if (action.payload.pillars)
            state.pillarsTreemapData = convertAPIDataToTreemapData(
              action.payload.pillars,
              'level0'
            );
          state.selectedPillar = undefined;
          state.selectedTheme = undefined;
          state.selectedTopic = undefined;
          state.selectedSubTopic = undefined;
          state.themesTreemapData = rootData;
          state.topicsTreemapData = rootData;
          state.subTopicsTreemapData = rootData;
          state.dataCategoryGridData = [];
          break;
        case 1:
          if (action.payload.items)
            state.themesTreemapData = convertAPIDataToTreemapData(action.payload.items, 'level1');
          state.selectedTheme = undefined;
          state.selectedTopic = undefined;
          state.selectedSubTopic = undefined;
          state.topicsTreemapData = rootData;
          state.subTopicsTreemapData = rootData;
          state.dataCategoryGridData = [];
          break;
        case 2:
          if (action.payload.items)
            state.topicsTreemapData = convertAPIDataToTreemapData(action.payload.items, 'level2');
          state.selectedTopic = undefined;
          state.selectedSubTopic = undefined;
          state.subTopicsTreemapData = rootData;
          state.dataCategoryGridData = [];
          break;
        case 3:
          if (action.payload.items)
            state.subTopicsTreemapData = convertAPIDataToTreemapData(
              action.payload.items,
              'level3'
            );
          state.selectedSubTopic = undefined;
          state.dataCategoryGridData = [];
          break;
      }
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryData.rejected, (state) => {
      state.pillarsTreemapData = rootData;
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryData.pending, (state) => {
      state.isPillarLoading = true;
    });
    builder.addCase(fetchDataCategoryGrid.fulfilled, (state, action) => {
      state.dataCategoryGridDataResponse = convertDataToGridData(action.payload).gridData;
      state.dataCategoryGridData = state.dataCategoryGridDataResponse;
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryGrid.rejected, (state) => {
      state.dataCategoryGridData = [];
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryGrid.pending, (state) => {
      state.isPillarLoading = true;
    });
    builder.addCase(fetchDataCategoryGridDownload.fulfilled, (state) => {
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryGridDownload.rejected, (state) => {
      state.isPillarLoading = false;
    });
    builder.addCase(fetchDataCategoryGridDownload.pending, (state) => {
      state.isPillarLoading = true;
    });
  }
});

export const {
  selectPillar,
  selectTheme,
  selectTopic,
  selectSubTopic,
  setPillarsList,
  setThemesList,
  setTopicsList,
  setSubTopicsList,
  setCountValue,
  setSelectedRegulations
} = dataCategoryPageSlice.actions;

export default dataCategoryPageSlice.reducer;
