import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SankeyExtraProperties, SankeyNode } from 'd3-sankey';
import {
  GET_REGULATORY_LINEAGE_DATA,
  REGULATORY_LINEAGE_MAX_LEVEL_X,
  regulatoryLineageRequestData
} from 'shared/helpers/constants';
import { ThreeMetrics } from 'shared/models/AppModel';
import { ExtraNodeProperties } from 'shared/models/Sankey';
import { regulatoryLineageRequests } from 'shared/utils/requests/RegulatoryLineage';
import { processRegulatoryData } from 'shared/utils/utils';

import { ResponseError } from '../../core/IError';
import { RootState } from '../store';

interface IRegulatoryLineageDataSliceState {
  RLPageData: Record<string, any>;
  pending: boolean;
  error?: string;
  metricCount: ThreeMetrics;
}

const initialState: IRegulatoryLineageDataSliceState = {
  metricCount: {
    primary: 0,
    secondary: 0,
    coreData: 0
  },
  RLPageData: {},
  pending: true,
  error: ''
};

interface IRequestParams {
  columnId: number;
  nodeId: number;
  parentKey: string;
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
}

const createRequestData = (
  selectedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
  columnId: number
) => {
  const data: any = {};
  for (let i = 0; i < columnId; i++) {
    if (regulatoryLineageRequestData.length > i) {
      const node = selectedNodes.find((value) => {
        return value.levelX == i;
      });
      if (node) data[regulatoryLineageRequestData[i]] = node.key;
    }
  }
  return data;
};

export const fetchRLPageData = createAsyncThunk<
  any,
  IRequestParams,
  { rejectValue: ResponseError }
>(
  GET_REGULATORY_LINEAGE_DATA,
  async ({ columnId, nodeId, parentKey, selectedNodes }: IRequestParams, thunkApi) => {
    const reqData = createRequestData(selectedNodes, columnId);
    const response = await regulatoryLineageRequests.getRegulatoryData(columnId, nodeId, reqData);
    if (response.isSuccess) {
      const data: any = response.getValue();
      if (columnId == REGULATORY_LINEAGE_MAX_LEVEL_X + 1) return data;
      const {
        rlPageSlice: { RLPageData }
      }: any = thunkApi.getState();
      const finalData = processRegulatoryData(data.items, columnId, parentKey);
      return { data: { ...RLPageData, [columnId]: finalData }, count: data.count };
    }
    return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
  }
);

export const rlPageSlice = createSlice({
  name: 'rlPageSlice',
  initialState,
  reducers: {
    updateMetricCount: (
      state: IRegulatoryLineageDataSliceState,
      action: PayloadAction<ThreeMetrics>
    ) => {
      state.metricCount = action.payload;
    }
  },
  extraReducers: (reducers) => {
    reducers
      .addCase(fetchRLPageData.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchRLPageData.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(fetchRLPageData.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data[0]) {
          state.pending = false;
          state.RLPageData = payload.data;
          state.metricCount = payload.count;
        } else {
          state.metricCount = payload.count;
        }
      });
  }
});

export const selectRLPageData = (state: RootState) => {
  return state.rlPageSlice;
};
export const rlPageSliceActions = rlPageSlice.actions;
// export the reducers
export default rlPageSlice.reducer;
