import { MotifIcon } from '@ey-xd/motif-react';
import { DataSourcingPageIcon } from 'images/dataSouringPageIcon';
import React from 'react';
import { TopicColorMap, TopicIconMap } from 'shared/data/dataSourcingPage';
import { useAppStore } from 'shared/redux/hooks';

import styles from './dataSourcingTopicTitle.module.scss';

interface DataSourcingTopicTitleProps {
  name?: string;
  size?: 'medium' | 'large';
}

const DataSourcingTopicTitle: React.FC<DataSourcingTopicTitleProps> = ({ name, size }) => {
  const { selectedPillar } = useAppStore().dataSourcingPageSlice;
  const topicIcon = TopicIconMap.get(name || '');
  const topicColor = selectedPillar?.pillarName ? TopicColorMap.get(selectedPillar.pillarName) : '';

  let fontClass = 'motif-body1-default-regular';
  if (size === 'large') {
    fontClass = 'motif-h5-default-regular';
  }

  return (
    <div className={styles.topicTitle}>
      <div className={styles.topicTitleIcon} style={{ background: topicColor }}>
        <MotifIcon
          className={styles.icon}
          src={`data:image/svg+xml;base64,${topicIcon || DataSourcingPageIcon.DefaultSvg}`}
        />
      </div>
      <div className={fontClass}>{name}</div>
    </div>
  );
};

export default DataSourcingTopicTitle;
