import { MotifCard, MotifProgressLoader } from '@ey-xd/motif-react';
import clsx from 'clsx';
import DefinedMetric from 'components/pageContents/molecules/home/definedMetrics/definedMetric.component';
import { FrameworkAndRegulationContainer } from 'components/pageContents/molecules/home/frameworkAndRegulation/frameworkAndRegulation.component';
import { MetricExplorerContainer } from 'components/pageContents/molecules/home/metricExplorer/metricExplorer.component';
import { OverlapAnalysisContainer } from 'components/pageContents/molecules/home/overlapAnalysis/overlapAnalysis.component';
import { useAppSelector } from 'shared/redux/hooks';
import { selectPageLoader } from 'shared/redux/slice/pageLoaderSlice';

import styles from './homePage.module.scss';

const HomePage = (): JSX.Element => {
  const { pageLoader } = useAppSelector(selectPageLoader);

  return (
    <div className="motif-container">
      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className={clsx('motif-col-lg-6', 'motif-col-md-6', 'motif-col-xs-12', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <FrameworkAndRegulationContainer />
          </MotifCard>
        </div>

        <div className={clsx('motif-col-lg-6', 'motif-col-md-6', 'motif-col-xs-12', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <DefinedMetric />
          </MotifCard>
        </div>
      </div>

      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className={clsx('motif-col-lg-6', 'motif-col-md-6', 'motif-col-xs-12', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <OverlapAnalysisContainer />
          </MotifCard>
        </div>
        <div className={clsx('motif-col-lg-6', 'motif-col-md-6', 'motif-col-xs-12', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <MetricExplorerContainer />
          </MotifCard>
        </div>
      </div>

      <MotifProgressLoader show={pageLoader} />
    </div>
  );
};
export default HomePage;
