const enum ApiStatus {
    NONE,
    LOADING,
    SUCCESS,
    ERROR
}

const enum HttpHeaderKey {
    ContentType = 'Content-Type',
    ActivePodId = 'Active-Pod-Id',
    Authorization = 'Authorization',
    ConsistencyLevel = 'ConsistencyLevel',
    Tenant = 'Tenant',
    Locale = 'locale'
}

const enum HttpStatusCode {
    Success = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    InternalServerError = 500
}
const enum HttpHeaderValue {
    MultipartFormData = 'multipart/form-data',
    ApplicationJson = 'application/json',
    Eventual = 'eventual'
}
export enum HttpHeader {
    CONTENT_TYPE = 'Content-Type',
    AUTHORIZATION = 'Authorization',
    TENANT_ID = 'TenantId',
    ACCESS_CONTROL_ALLOW_ORIGIN = 'Access-Control-Allow-Origin'
}
export { ApiStatus, HttpStatusCode, HttpHeaderKey, HttpHeaderValue };
