import React, { FC } from 'react';

const Custom404: FC = (): JSX.Element => {
  return (
    <>
      <h2>Page not found.</h2>
    </>
  );
};

export default Custom404;
