import { ThreeMetrics } from './AppModel';

export interface TreeNode {
  value: string;
  count: ThreeMetrics;
}

export enum ZoomTypes {
  IN = 'in',
  OUT = 'out'
}

export enum rlTopicUrlParams {
  topLevel = 'topLevel',
  own_workforce = 'own_workforce',
  general_disclosures = 'general_disclosures',
  business_conduct = 'business_conduct',
  climate_change = 'climate_change',
  ifrs_s2_climate_related_disclosures = 'ifrs_s2_climate_related_disclosures'
}
