import React from 'react';
import clsx from 'clsx';

import styles from './metricsCountWidget.module.scss';
import RoundIconContainer from 'components/pageContents/atoms/roundIconContainer/roundIconContainer.component';

interface IMetricsCountWidgetProps {
  icon: string;
  metricsName: string;
  count: number;
}

const MetricsCountWidget = ({
  icon,
  metricsName,
  count
}: IMetricsCountWidgetProps): JSX.Element => {
  const roundCustomStyle = {
    backgroundColor: '#3F3F4C'
  };
  return (
    <div className="motif-container no-padding">
      <div className={clsx(styles.topComponent, 'motif-row')}>
        <RoundIconContainer customStyle={roundCustomStyle} icon={icon} />
        <div className={clsx(styles.topComponentText)}>{metricsName}</div>
      </div>
      <div className={clsx(styles.bottomComponent, 'motif-row')}>
        <span>{Number(count).toLocaleString('en-US')}</span>
      </div>
    </div>
  );
};
export default MetricsCountWidget;
