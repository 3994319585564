/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MotifCheckbox,
  MotifFormField,
  MotifIcon,
  MotifSearch,
  MotifTooltip
} from '@ey-xd/motif-react';
import Popover from '@mui/material/Popover';
import LinkNode from 'components/pageContents/atoms/sankey/node/linkNode/linkNode.component';
import type { SankeyExtraProperties, SankeyGraph, SankeyNode, sankey } from 'd3-sankey';
import { LineagePageIcons } from 'images/lineagePageIcons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ExtraNodeProperties } from 'shared/models/Sankey';

import styles from './node.module.scss';

// Props
type NodeProps<N extends SankeyExtraProperties, L extends SankeyExtraProperties> = {
  currentNode: SankeyNode<SankeyExtraProperties, SankeyExtraProperties>;
  index: any;
  width?: number;
  height?: number;
  graph?: SankeyGraph<any, any>;
  sankey?: typeof sankey;
  apiNodeData: SankeyNode<SankeyExtraProperties, SankeyExtraProperties>[];
  onNodeClicked: any;
  maxLevelXSelected: number;
  maxLevelX: number;
  openModal?: any;
  updateSearchOnView: any;
  updateNodeExpansionOnView: any;
  updateFilteredRegulation?: any;
};

const Node = <N extends SankeyExtraProperties, L extends SankeyExtraProperties>({
  currentNode,
  index,
  width,
  height,
  onNodeClicked,
  graph,
  maxLevelXSelected,
  apiNodeData,
  updateSearchOnView,
  updateNodeExpansionOnView,
  updateFilteredRegulation
}: NodeProps<N, L>): JSX.Element => {
  let { x0, x1, y0, y1 } = currentNode;
  const {
    isSelected,
    isExpanded,
    levelX,
    levelY,
    name,
    searchBox,
    key,
    isFilter,
    filterOptions,
    showDisableFilterTooltip,
    info
  } = currentNode;
  // Query content handler
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checkedFilter, setCheckedFilter] = useState<string[]>([]);

  // Handling node x0,x1,y0,y1 is NaN | undefined value occurs
  x0 = Number.isNaN(x0) ? 0 : Number(x0);
  x1 = Number.isNaN(x1) ? 0 : Number(x1);
  y0 = Number.isNaN(y0) ? 40 : Number(y0);
  y1 = Number.isNaN(y1) ? 70 : Number(y1);
  const defaultWidth = x1 - x0;
  const defaultHeight = y1 - y0;

  // Check expanded node to calculate node height
  const checkBeforeExpansion = graph?.nodes.findIndex((node) => {
    return node.isExpanded && node.levelX == levelX && node.levelY < levelY;
  });

  // set the filter select all
  useEffect(() => {
    if (filterOptions && filterOptions.length > 0) {
      setCheckedFilter(filterOptions);
    }
  }, [filterOptions]);

  // Node params calculation
  const nodeWidth = width ? width : defaultWidth;
  const nodeHeight = height ? (isExpanded ? 180 : height) : defaultHeight;
  const nodeY: number = height
    ? checkBeforeExpansion && checkBeforeExpansion > 0
      ? Number((levelY - 1) * 80 + 80 + 120) // Expansion height also added
      : Number((levelY - 1) * 80 + 80)
    : Number(y0);

  const textX = width ? Number(300 * levelX) : Number(x0);
  const textY = height ? nodeY : Number(y0) + nodeHeight / 2 + 6;

  // Function to get all the next level nodes from present node
  const nextLevelNodes = () => {
    const nodes = apiNodeData
      .filter(
        (node) => node.levelX == levelX + 1 && (node.parentKey == key || !('parentKey' in node))
      )
      .map((data) => {
        data.sourceLinks = [];
        data.targetLinks = [];
        return data;
      });

    return nodes.length <= 1 ? [] : JSON.parse(JSON.stringify(nodes));
  };

  // Switching nodes of next level if same level node is clicked
  const findSameLevelNodes = () => {
    const lastSelectedParent = graph?.nodes.find((node) => {
      return node.isSelected && node.levelX == levelX - 1;
    });
    const sameLevelNodes = apiNodeData
      .filter((node) => {
        return node.parentKey == lastSelectedParent.key && node.levelX == levelX;
      })
      .map((data) => {
        data.sourceLinks = [];
        data.targetLinks = [];
        return data;
      });

    return {
      parentKey: lastSelectedParent.key,
      // sameLevelNodes: [...sameLevelNodes]
      sameLevelNodes: JSON.parse(JSON.stringify(sameLevelNodes))
    };
  };

  const handleNodeClick = () => {
    // Todo: need to check how below condition executing on other type of sankeys
    if (levelX == 4) {
      graph?.nodes.find((node) => {
        if (node.levelX == levelX && node.isFilter) {
          node.isFilter = false;
          node.showDisableFilterTooltip = true;
        }
      });
    }
    if (levelX > maxLevelXSelected) {
      let updatedNodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[] = [];
      if (graph) {
        updatedNodes = graph?.nodes.map((data, i) => {
          if (data.levelX <= levelX) {
            const unmutatedData = { ...data };
            if (i == index) {
              unmutatedData.isSelected = true;
            }
            if (data.levelX == levelX && data.isSelected) unmutatedData.isSelected = false;
            return unmutatedData;
          }
        });
      }
      if (levelX != 0 && graph) {
        const previousSelected = graph?.nodes.findIndex((node) => {
          return node.levelX == levelX - 1 && node.isSelected;
        });
        // Remove the same level link
        const links = graph?.links.filter((link) => {
          return link.target.levelX != levelX;
        });
        graph['links'] = [...links];
        return onNodeClicked(
          updatedNodes,
          links?.concat([{ source: previousSelected, target: index }]),
          currentNode,
          levelX
        );
      }
      onNodeClicked(updatedNodes, graph?.links, currentNode, levelX);
    } else if (levelX == maxLevelXSelected) {
      //Handling selection on same level
      const selectedIndex = graph?.nodes.findIndex((node, i) => {
        return node.levelX == levelX && node.isSelected;
      }) as number;
      if (graph && selectedIndex != -1) {
        // Node isSelected updated
        graph.nodes[selectedIndex].isSelected = false;
        graph.nodes[index].isSelected = true;

        const updatedNodeData = graph.nodes.filter((node) => {
          return node.levelX != levelX + 1;
        });

        const latestNextLevel = nextLevelNodes();
        const updatedNodeIndex = updatedNodeData.concat(latestNextLevel).findIndex((node) => {
          return node.key == key;
        });
        //Link update
        if (levelX != 0) {
          const selectedLink = graph.links.find((link) => {
            return link.target.index == selectedIndex;
          });
          graph.links.splice(selectedLink.index, 1);
          return onNodeClicked(
            updatedNodeData.concat(latestNextLevel),
            graph.links.concat([{ source: selectedLink.source, target: updatedNodeIndex }]),
            currentNode,
            levelX
          );
        }
        return onNodeClicked(
          updatedNodeData.concat(latestNextLevel),
          graph.links,
          currentNode,
          levelX
        );
      }
    } else if (maxLevelXSelected > levelX) {
      // Link deletion
      if (graph) {
        const links = graph.links.filter((link) => {
          return link.source.levelX < levelX;
        });
        const selectedIndex = graph?.nodes.findIndex((node, i) => {
          return node.levelX == levelX && node.isSelected && i != index;
        }) as number;
        const nodes = graph.nodes.filter((node) => {
          if (node.levelX > levelX) {
            node.isExpanded = false;
            node.isSelected = false;
          }
          return node.levelX <= levelX;
        });
        if (selectedIndex != -1 && selectedIndex != index) {
          graph.nodes[selectedIndex].isSelected = false;
          graph.nodes[index].isSelected = true;
          const updatedNodeIndex = nodes.findIndex((node) => {
            return node.key == key;
          });
          if (levelX != 0) {
            const selectedLink = links.find((link) => {
              return link.target.index == selectedIndex;
            });
            links.splice(selectedLink.index, 1);
            return onNodeClicked(
              nodes,
              links.concat([{ source: selectedLink.source, target: updatedNodeIndex }]),
              currentNode,
              levelX
            );
          }
        }
        return onNodeClicked(nodes, links, currentNode, levelX);
      }
    }
  };

  const handleSearchBoxChange = (e: any) => {
    const query = e.target.value;
    setSearchQuery(query);

    let nodeLevelY = 1;
    const { parentKey, sameLevelNodes } = findSameLevelNodes();
    const selectedNode = graph?.nodes.find((node: any) => {
      return node.parentKey == parentKey && node.isSelected;
    });

    if (selectedNode) {
      // if node is selected in the searching column
      selectedNode.levelY = nodeLevelY++;
      const filteredResults = sameLevelNodes?.filter((node: any) => {
        if (
          node.name.toLowerCase().includes(query.toLowerCase()) &&
          node.key != selectedNode.key &&
          node.parentKey
        ) {
          node.levelY = nodeLevelY++;
          return node;
        }
      });
      const nodeDataSet: any = [selectedNode];

      const remainingNodes: any = graph?.nodes.filter((node) => {
        return node.parentKey != parentKey;
      });
      const updatedLink = graph?.links.map((link) => {
        if (link.source.index == selectedNode.index) {
          return { source: remainingNodes?.length, target: link.target };
        } else if (link.target.index == selectedNode.index) {
          return { source: link.source, target: remainingNodes?.length };
        } else return link;
      });
      return updateSearchOnView(
        [...remainingNodes, ...nodeDataSet, ...filteredResults],
        updatedLink
      );
    } else {
      const filteredResults = sameLevelNodes?.filter((node: any) => {
        if (node.name.toLowerCase().includes(query.toLowerCase())) {
          node.levelY = nodeLevelY++;
          return node;
        }
      });
      const remainingNodes = graph?.nodes.filter((node) => {
        return node.parentKey != parentKey;
      });
      return updateSearchOnView(remainingNodes?.concat(filteredResults), graph?.links);
    }
  };

  const handleOnExpansionClick = (e: any) => {
    e.stopPropagation();
    const checkAlreadyExpanded = graph?.nodes.findIndex((node) => {
      return node.isExpanded && node.key != key && node.levelX == levelX;
    });
    if (graph && checkAlreadyExpanded && checkAlreadyExpanded > 0) {
      graph.nodes[checkAlreadyExpanded].isExpanded = false;
    }
    const updatedNode = graph?.nodes.map((node) => {
      if (node.key == key && node.levelX == levelX)
        node.isExpanded = node.isExpanded ? false : true;
      return node;
    });
    return updateNodeExpansionOnView(updatedNode, graph?.links);
  };

  const handleChipClick = () => (event: any) => {
    event.stopPropagation();
    // This function will be called when the user click on the chips inside the node
  };

  const SearchBoxPlaceholder = 'Search for...';

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setFilterMenuAnchorEl(null);
  };

  const onFilterChange = (value: string) => {
    const values: string[] = checkedFilter;
    if (value == 'Select all') {
      const selectedFilterOptions = _.isEqual(checkedFilter, filterOptions) ? [] : filterOptions;
      setCheckedFilter(selectedFilterOptions);
      applyFilter(selectedFilterOptions);
    } else {
      if (!values.includes(value)) {
        const uniqueSet = new Set(values);
        uniqueSet.add(value);
        setCheckedFilter(Array.from(uniqueSet));
        applyFilter(Array.from(uniqueSet));
      } else {
        const selectedFilterOptions = values.filter((e) => e !== value);
        setCheckedFilter(selectedFilterOptions);
        applyFilter(selectedFilterOptions);
      }
    }
  };

  // Handler function to handle select change
  const applyFilter = (selectedOptions: string[]) => {
    const selectedVal = _.isEqual(filterOptions, selectedOptions) ? null : selectedOptions; // checking if the user selected all the options or not. if yes then it will return all the nodes.
    let nodeLevelY = 1;
    const { parentKey, sameLevelNodes } = findSameLevelNodes();
    const selectedNode = graph?.nodes.find((node: any) => {
      return node.parentKey == parentKey && node.isSelected;
    });
    if (selectedVal) {
      if (selectedNode) {
        selectedNode.levelY = nodeLevelY++;
        const nodeDataSet: any = [selectedNode];

        const remainingNodes: any = graph?.nodes.filter((node) => {
          return node.parentKey != parentKey;
        });

        const filteredResults = sameLevelNodes?.filter((node: any) => {
          if (
            selectedVal.includes(node?.extraData?.regulationName) &&
            node.key !== selectedNode.key
          ) {
            node.levelY = nodeLevelY++;
            return node;
          }
        });

        const updatedLink = graph?.links.map((link) => {
          if (link.source.index == selectedNode.index) {
            return { source: remainingNodes?.length, target: link.target };
          } else if (link.target.index == selectedNode.index) {
            return { source: link.source, target: remainingNodes?.length };
          } else return link;
        });

        return updateFilteredRegulation(
          [...remainingNodes, ...nodeDataSet, ...filteredResults],
          updatedLink,
          selectedOptions
        );
      } else {
        const filteredResults = sameLevelNodes?.filter((node: any) => {
          if (selectedVal.includes(node?.extraData?.regulationName)) {
            node.levelY = nodeLevelY++;
            return node;
          }
        });
        const remainingNodes = graph?.nodes.filter((node) => {
          return node.parentKey != parentKey;
        });
        return updateFilteredRegulation(
          remainingNodes?.concat(filteredResults),
          graph?.links,
          selectedOptions
        );
      }
    } else {
      const remainingNodes: any = graph?.nodes.filter((node) => {
        return node.parentKey != parentKey;
      });
      return updateFilteredRegulation(
        remainingNodes.concat(sameLevelNodes),
        graph?.links,
        selectedOptions
      );
    }
  };

  const filterMenuOpen = Boolean(filterMenuAnchorEl);
  const filterMenuID = filterMenuOpen ? 'simple-popover' : undefined;

  return levelY != 0 ? (
    <g style={{ pointerEvents: 'all', alignItems: 'center' }}>
      <foreignObject width={nodeWidth} height={nodeHeight} x={textX} y={textY}>
        <LinkNode
          nodeText={name}
          isSelected={isSelected}
          isExpanded={isExpanded}
          toolTipData={currentNode?.extraData?.toolTipData}
          normalChips={currentNode?.extraData?.normalChips}
          dotChips={currentNode?.extraData?.dotChips}
          handleNodeClick={handleNodeClick}
          handleOnExpansionClick={handleOnExpansionClick}
          handleChipClick={handleChipClick}
        />
      </foreignObject>
    </g>
  ) : (
    <>
      <g style={{ pointerEvents: 'all', alignItems: 'center' }}>
        <foreignObject
          width={nodeWidth}
          height={80}
          x={textX}
          y={0}
          style={{ alignItems: 'center' }}>
          <div>
            <div style={{ position: 'relative', zIndex: 1 }} className={styles.headingNode}>
              <span>{name}</span>
              {info && (
                <MotifTooltip
                  placement="bottom"
                  trigger={
                    <MotifIcon
                      className={styles.infoIcons}
                      src={`data:image/svg+xml;base64,${LineagePageIcons.infoSvg}`}
                    />
                  }>
                  {info}
                </MotifTooltip>
              )}
              {showDisableFilterTooltip && !isFilter && (
                <MotifTooltip
                  placement="bottom"
                  trigger={
                    <MotifIcon
                      className={styles.infoIcons}
                      style={{ opacity: '45%' }}
                      src={`data:image/svg+xml;base64,${LineagePageIcons.filterSvg}`}
                    />
                  }>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        'Filter is disabled. Please go back to Sub topic to make Filter enable.'
                    }}
                  />
                </MotifTooltip>
              )}
              {name && isFilter && (
                <MotifIcon
                  title="Click here to view the filter menu"
                  className={styles.filterIcon}
                  onClick={handleClickPopover}
                  src={`data:image/svg+xml;base64,${LineagePageIcons.filterSvg}`}
                />
              )}
            </div>
            {searchBox && (
              <MotifFormField>
                <MotifSearch
                  className={styles.searchBox}
                  id={`search-${levelX}`}
                  aria-label="Search"
                  onChange={handleSearchBoxChange}
                  placeholder={SearchBoxPlaceholder}
                  value={searchQuery}
                />
              </MotifFormField>
            )}
          </div>
        </foreignObject>
      </g>
      {filterOptions && (
        <Popover
          id={filterMenuID}
          open={filterMenuOpen}
          anchorEl={filterMenuAnchorEl}
          onClose={handleCloseFilterMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <div className="sankeyNodeFilterContainer">
            <MotifCheckbox
              key="Select all"
              checked={_.isEqual(checkedFilter, filterOptions)}
              id={`checkbox-1`}
              name={`checkbox-1`}
              onChange={() => {
                onFilterChange('Select all');
              }}
              value="Select all">
              Select all
            </MotifCheckbox>
            {filterOptions.map((filterName: string, index: number) => (
              <MotifCheckbox
                key={index}
                checked={checkedFilter.includes(filterName)}
                id={`checkbox-${filterName}`}
                name={`checkbox-${filterName}`}
                onChange={() => {
                  onFilterChange(filterName);
                }}
                value={filterName}>
                {filterName}
              </MotifCheckbox>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
};

export default Node;
