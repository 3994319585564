import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';

import { apiRequest, graphConfig, loginRequest, msalInstance } from '../../msalConfig';

export const callMsGraphApis = async (urlParameter: string, blobData: boolean) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    console.log('No active user');
  }
  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0]
  };

  const token = await msalInstance.acquireTokenSilent(request);
  const accessToken = token.accessToken;
  const headers = {
    Authorization: `Bearer ${accessToken}`
  };
  const response = await axios.get<any>(`${graphConfig.graphMeEndpoint}${urlParameter}`, {
    headers,
    ...(blobData && { responseType: 'blob' })
  });
  return response.data;
};

export const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    ...apiRequest,
    account: activeAccount || accounts[0]
  };
  let accessToken = null;
  await msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      accessToken = response.accessToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenPopup({
            ...loginRequest
          })
          .then((accessTokenResponse) => {
            accessToken = accessTokenResponse.idToken;
          })
          .catch(function (error) {
            console.log('🚀 ~ acquireAccessToken ~ error:', error);
            window.history.pushState(null, '', '/logout');
            window.history.replaceState(null, '', '/logout');
            window.location.href = '/logout';
          });
      }
    });
  return accessToken;
};
