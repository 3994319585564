import { DataSourcingPageIcon } from 'images/dataSouringPageIcon';

export const TopicIconMap = new Map([
  ['Climate Change', DataSourcingPageIcon.CloudSunnySvg],
  ['Water and Marine Resources', DataSourcingPageIcon.WateringSoilSvg],
  ['Pollution', DataSourcingPageIcon.FireFlameSvg],
  ['Biodiversity and ecosystems', DataSourcingPageIcon.LeafSvg],
  ['Resource use and circular economy', DataSourcingPageIcon.IndustrySvg],

  ['Own Workforce', DataSourcingPageIcon.OwnWorkforceSvg],
  ['Workers in value chain', DataSourcingPageIcon.GroupSvg],
  ['Affected communities', DataSourcingPageIcon.CommunitySvg],
  ['Consumers and end users', DataSourcingPageIcon.UserSvg],
  ['Business Conduct', DataSourcingPageIcon.PresentationSvg],
  ['General requirements', DataSourcingPageIcon.NotesSvg],
  ['General disclosures', DataSourcingPageIcon.WateringSoilSvg],

  ['General', DataSourcingPageIcon.GeneralSvg],
  ['Social', DataSourcingPageIcon.SocialSvg],
  ['Governance', DataSourcingPageIcon.GovernanceSvg],
  ['Environmental', DataSourcingPageIcon.EnvironmentSvg]
]);

export const TopicNameMap = new Map([
  ['Climate Change', 'dataSourcingGridE1'],
  ['Pollution', 'dataSourcingGridE2'],
  ['Water and Marine Resources', 'dataSourcingGridE3'],
  ['Biodiversity and ecosystems', 'dataSourcingGridE4'],
  ['Resource use and circular economy', 'dataSourcingGridE5'],
  ['Own Workforce', 'dataSourcingGridS1'],
  ['Business conduct', 'dataSourcingGridG1']
]);

export const TopicColorMap = new Map([
  ['Environmental', '#48E674'],
  ['Social', '#FDBC84'],
  ['Governance', '#44E0DE'],
  ['General', '#D4C4EA']
]);

export const DataSourcingList = ['SAP'];

export const TopicNameCSVMap = new Map([
  ['Climate Change', process.env.REACT_APP_CLIMATE_CHANGE_CSV_URL],
  ['Pollution', process.env.REACT_APP_POLLUTION_CSV_URL],
  ['Water and Marine Resources', process.env.REACT_APP_WATER_AND_MARINE_RESOURCES_CSV_URL],
  ['Biodiversity and ecosystems', process.env.REACT_APP_BIODIVERSITY_AND_ECOSYSTEMS_CSV_URL],
  [
    'Resource use and circular economy',
    process.env.REACT_APP_RESOURCE_USE_AND_CIRCULAR_ECONOMY_CSV_URL
  ],
  ['Own Workforce', process.env.REACT_APP_OWN_WORKFORCE_CSV_URL],
  ['Business conduct', process.env.REACT_APP_BUSINESS_CONDUCT_CSV_URL]
]);
