// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  MotifBreadcrumb,
  MotifBreadcrumbItem,
  MotifButton,
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifIcon,
  MotifProgressLoader
} from '@ey-xd/motif-react';
import clsx from 'clsx';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import {
  fetchDataCategoryData,
  fetchDataCategoryGrid,
  fetchDataCategoryGridDownload,
  selectPillar,
  selectSubTopic,
  selectTheme,
  selectTopic,
  setCountValue,
  setPillarsList,
  setSelectedRegulations,
  setSubTopicsList,
  setThemesList,
  setTopicsList
} from 'shared/redux/slice/dataCategoryPageSlice';

import { DataCategoryPageIcon } from '../../../../images/dataCategoryPageIcon';
import DataCategoryGrid from '../../molecules/dataCategoryExplorer/dataCategoryGrid/dataCategoryGrid.component';
import { Treemap } from '../../molecules/dataCategoryExplorer/treeMap/treeMap.component';
import styles from './dataCategoryExplorerPage.module.scss';

interface DataCategoryProps {
  isSideNavCollapsed: boolean;
}

const DataCategoryExplorerPage = ({ isSideNavCollapsed }: DataCategoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    selectedPillar,
    selectedTheme,
    selectedTopic,
    pillarsTreemapData,
    themesTreemapData,
    topicsTreemapData,
    selectedSubTopic,
    subTopicsTreemapData,
    isPillarLoading,
    dataCategoryGridData,
    isGridLoading,
    selectedRegulations,
    topWidgetRegulations
  } = useAppStore().dataCategoryPageSlice;

  const { userPermissions } = useAppStore().userProfileSlice;

  const [error, setError] = useState<string>('');

  const pageName = 'Data Category Explorer';

  useEffect(() => {
    dispatch(fetchDataCategoryData({ columnId: 0 })).then((data) => {
      if (data.type.indexOf('rejected') >= 0) {
        setError('Error occured while fetching Data category details');
      }
    });
    return () => {
      dispatch(selectPillar());
      dispatch(selectTheme());
      dispatch(selectTopic());
      dispatch(setCountValue());
    };
  }, [dispatch]);

  const updateTreeData = (
    treeData: Tree,
    currentKey: string,
    alreadySelected: string,
    value: boolean,
    level: string
  ) => {
    const updatedTreeDataChildren = [...treeData.children];
    const currentIndex = updatedTreeDataChildren.findIndex((leaf) => {
      return currentKey == leaf.key;
    });
    const currentValue = {
      ...updatedTreeDataChildren.find((leaf) => {
        return currentKey == leaf.key;
      })
    };
    if (currentIndex >= 0) {
      if (currentKey == alreadySelected) {
        currentValue.isSelect = value;
        updatedTreeDataChildren[currentIndex] = currentValue;
      } else {
        currentValue.isSelect = true;
        updatedTreeDataChildren[currentIndex] = currentValue;
        const alreadySelectedIndex = updatedTreeDataChildren.findIndex((leaf) => {
          return alreadySelected == leaf.key;
        });
        const alreadySelectedValue = {
          ...updatedTreeDataChildren.find((leaf) => {
            return alreadySelected == leaf.key;
          })
        };
        alreadySelectedValue.isSelect = false;
        updatedTreeDataChildren[alreadySelectedIndex] = alreadySelectedValue;
      }
    }

    switch (level) {
      case 'level0':
        if (value) {
          dispatch(selectPillar(currentValue));
          dispatch(
            fetchDataCategoryData({ columnId: 1, body: { pillarNode: currentValue.key } })
          ).then((data) => {
            if (data.type.indexOf('rejected') >= 0) {
              setError('Error occured while fetching Data category details');
            }
          });
        } else dispatch(selectPillar());
        dispatch(setPillarsList({ ...treeData, children: updatedTreeDataChildren }));
        break;
      case 'level1':
        if (value) {
          dispatch(selectTheme(currentValue));
          dispatch(
            fetchDataCategoryData({
              columnId: 2,
              body: { pillarNode: selectedPillar?.key, themeNode: currentValue.key }
            })
          ).then((data) => {
            if (data.type.indexOf('rejected') >= 0) {
              setError('Error occured while fetching Data category details');
            }
          });
        } else dispatch(selectTheme());
        dispatch(setThemesList({ ...treeData, children: updatedTreeDataChildren }));
        break;
      case 'level2':
        if (value) {
          dispatch(selectTopic(currentValue));
          dispatch(
            fetchDataCategoryData({
              columnId: 3,
              body: {
                pillarNode: selectedPillar?.key,
                themeNode: selectedTheme?.key,
                topicNode: currentValue.key
              }
            })
          ).then((data) => {
            if (data.type.indexOf('rejected') >= 0) {
              setError('Error occured while fetching Data category details');
            }
          });
        } else dispatch(selectTopic());
        dispatch(setTopicsList({ ...treeData, children: updatedTreeDataChildren }));
        break;
      case 'level3':
        if (value) {
          dispatch(selectSubTopic(currentValue));
          dispatch(
            fetchDataCategoryGrid({
              columnId: 4,
              body: {
                pillarNode: selectedPillar?.key,
                themeNode: selectedTheme.key,
                topicNode: selectedTopic?.key,
                subTopicNode: currentValue.key
              }
            })
          ).then((data) => {
            if (data.type.indexOf('rejected') >= 0) {
              setError('Error occured while fetching Data category details');
            }
          });
        } else dispatch(selectSubTopic());
        dispatch(setSubTopicsList({ ...treeData, children: updatedTreeDataChildren }));
        break;
    }
  };

  const handleButtonClick = (selectedRegulation: string) => {
    if (selectedRegulation == 'all' && selectedRegulations.length > 0) {
      dispatch(setSelectedRegulations([]));
    } else {
      const temp = [...selectedRegulations];
      const index = temp.findIndex((item) => {
        return selectedRegulation == item;
      });
      if (index != -1) {
        temp.splice(index, 1);
        dispatch(setSelectedRegulations(temp));
      } else {
        dispatch(setSelectedRegulations([...temp, selectedRegulation]));
      }
    }
  };
  const breadcrumbData = (): JSX.Element => {
    if (selectedPillar && selectedPillar.name) {
      if (selectedTheme && selectedTheme.name) {
        if (selectedTopic && selectedTopic.name) {
          if (selectedSubTopic && selectedSubTopic.name) {
            return (
              <MotifBreadcrumb
                triggerButtonProps={{
                  'aria-label': 'Custom Trigger Button aria-label',
                  title: 'Custom Trigger Button title'
                }}>
                <MotifBreadcrumbItem>
                  <a>{selectedPillar.name}</a>
                </MotifBreadcrumbItem>
                <MotifBreadcrumbItem>
                  <a>{selectedTheme.name}</a>
                </MotifBreadcrumbItem>
                <MotifBreadcrumbItem>
                  <a>{selectedTopic.name}</a>
                </MotifBreadcrumbItem>
                <MotifBreadcrumbItem>
                  <a>{selectedSubTopic.name}</a>
                </MotifBreadcrumbItem>
              </MotifBreadcrumb>
            );
          }
          return (
            <MotifBreadcrumb
              triggerButtonProps={{
                'aria-label': 'Custom Trigger Button aria-label',
                title: 'Custom Trigger Button title'
              }}>
              <MotifBreadcrumbItem>
                <a>{selectedPillar.name}</a>
              </MotifBreadcrumbItem>
              <MotifBreadcrumbItem>
                <a>{selectedTheme.name}</a>
              </MotifBreadcrumbItem>
              <MotifBreadcrumbItem>
                <a>{selectedTopic.name}</a>
              </MotifBreadcrumbItem>
            </MotifBreadcrumb>
          );
        }
        return (
          <MotifBreadcrumb
            triggerButtonProps={{
              'aria-label': 'Custom Trigger Button aria-label',
              title: 'Custom Trigger Button title'
            }}>
            <MotifBreadcrumbItem>
              <a>{selectedPillar.name}</a>
            </MotifBreadcrumbItem>
            <MotifBreadcrumbItem>
              <a>{selectedTheme.name}</a>
            </MotifBreadcrumbItem>
          </MotifBreadcrumb>
        );
      }
      return (
        <MotifBreadcrumb
          triggerButtonProps={{
            'aria-label': 'Custom Trigger Button aria-label',
            title: 'Custom Trigger Button title'
          }}>
          <MotifBreadcrumbItem>
            <a>{selectedPillar.name}</a>
          </MotifBreadcrumbItem>
        </MotifBreadcrumb>
      );
    }
  };

  const handleErrorModalClose = () => {
    setError('');
  };

  return (
    <>
      <div
        className={clsx(
          'motif-container',
          isSideNavCollapsed ? styles.fixedContainer : styles.fixedContainerWithSideBar
        )}>
        <MotifProgressLoader show={isPillarLoading} fullscreen />
        <div className={clsx('widgetSpacing', 'motif-row', styles.displayFlexColumn)}>
          <div
            className={
              isSideNavCollapsed
                ? clsx('pageHeading', styles.headingWithoutSideNav, styles.displayFlexRow)
                : clsx('pageHeading', styles.heading, styles.displayFlexRow)
            }>
            <h2>{pageName}</h2>
            <div className={styles.displayFlexRow}>
              {topWidgetRegulations.map((regulation) => {
                return (
                  <div
                    className={clsx(styles.topTag, styles.displayFlexRow)}
                    key={regulation.regulationName}>
                    <span style={{ paddingRight: '6px' }}>{regulation.regulationName}</span>
                    <span
                      style={{
                        background: 'var(--Neutrals-Dark-theme-100, #33333E)',
                        padding: '0px 6px'
                      }}>
                      {regulation.value ? regulation.value : 0}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="motif-h6" style={{ color: 'var(--Neutrals-Dark-theme-700, #A4A3B1)' }}>
            Select an area below to change view
          </div>
          <div>{breadcrumbData()}</div>
        </div>
      </div>
      <div className={clsx('motif-container', styles.container)}>
        <Treemap
          updated={selectedPillar?.name}
          data={pillarsTreemapData}
          width={isSideNavCollapsed ? window.innerWidth - 150 : window.innerWidth - 350}
          height={150}
          updateTreeData={updateTreeData}
          level={'level0'}
        />
        {selectedPillar && (
          <Treemap
            updated={selectedTheme?.name}
            data={themesTreemapData}
            width={isSideNavCollapsed ? window.innerWidth - 110 : window.innerWidth - 350}
            height={150}
            updateTreeData={updateTreeData}
            level={'level1'}
          />
        )}
        {selectedPillar && selectedTheme && (
          <Treemap
            updated={selectedTopic?.name}
            data={topicsTreemapData}
            width={isSideNavCollapsed ? window.innerWidth - 110 : window.innerWidth - 350}
            height={150}
            updateTreeData={updateTreeData}
            level={'level2'}
          />
        )}
        {selectedPillar && selectedTheme && selectedTopic && (
          <Treemap
            updated={selectedSubTopic?.name}
            data={subTopicsTreemapData}
            width={isSideNavCollapsed ? window.innerWidth - 110 : window.innerWidth - 350}
            height={450}
            updateTreeData={updateTreeData}
            level={'level3'}
          />
        )}
        {selectedPillar && selectedTheme && selectedTopic && selectedSubTopic && (
          <div>
            <MotifProgressLoader show={isGridLoading} fullscreen />
            <MotifCard
              className={
                isSideNavCollapsed
                  ? styles.dataGridHeaderCard
                  : styles.dataGridHeaderCardWithSideBar
              }>
              <MotifCardHeader
                className={styles.displayFlexColumn}
                style={{
                  alignItems: 'flex-start',
                  background: 'var(--Neutrals-Dark-theme-100, #33333E)'
                }}>
                <span className="motif-h5">{selectedSubTopic.name}</span>
                <div
                  style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
                  <MotifButton
                    variant="primary-alt"
                    className={selectedRegulations.length == 0 ? styles.selectedButton : ''}
                    onClick={() => handleButtonClick('all')}>
                    All
                  </MotifButton>
                  <MotifButton
                    variant="primary-alt"
                    className={
                      selectedRegulations.findIndex((r) => r == 'csrd') != -1
                        ? styles.selectedButton
                        : ''
                    }
                    onClick={() => handleButtonClick('csrd')}>
                    CSRD
                  </MotifButton>
                  <MotifButton
                    variant="primary-alt"
                    className={
                      selectedRegulations.findIndex((r) => r == 'issb') != -1
                        ? styles.selectedButton
                        : ''
                    }
                    onClick={() => handleButtonClick('issb')}>
                    ISSB
                  </MotifButton>
                  <MotifButton
                    variant="primary-alt"
                    className={
                      selectedRegulations.findIndex((r) => r == 'tcfd') != -1
                        ? styles.selectedButton
                        : ''
                    }
                    onClick={() => handleButtonClick('tcfd')}>
                    TCFD
                  </MotifButton>
                  <MotifButton
                    variant="primary-alt"
                    className={
                      selectedRegulations.findIndex((r) => r == 'sfdr') != -1
                        ? styles.selectedButton
                        : ''
                    }
                    onClick={() => handleButtonClick('sfdr')}>
                    SFDR
                  </MotifButton>
                  <MotifButton
                    variant="primary-alt"
                    className={
                      selectedRegulations.findIndex((r) => r == 'pcaf') != -1
                        ? styles.selectedButton
                        : ''
                    }
                    onClick={() => handleButtonClick('pcaf')}>
                    PCAF
                  </MotifButton>
                </div>
              </MotifCardHeader>
              <MotifCardBody className={styles.dataGridHeader}>
                <div>
                  <div className="motif-subtitle-default-regular">
                    Data Category Explorer - Grid View
                  </div>
                  <div className={`motif-body2-default-regular ${styles.dataGridHeaderSubtitle}`}>
                    Theme-specific data laid out in a customizable table offering a selection of
                    columns and rows that can be exported in various formats.
                  </div>
                </div>
                {userPermissions &&
                  userPermissions.length > 0 &&
                  userPermissions.filter(
                    (permission) =>
                      permission.type == 'FrontendPermission' &&
                      permission.value == 'CanDownloadFromDataCategoryExplorer'
                  ).length > 0 && (
                    <MotifButton
                  variant="text"
                  className={styles.dataGridDownload}
                  onClick={() => {
                    dispatch(
                      fetchDataCategoryGridDownload({
                        columnId: 5,
                        body: {
                          pillarNode: selectedPillar?.key,
                          themeNode: selectedTheme.key,
                          topicNode: selectedTopic?.key,
                          subTopicNode: selectedSubTopic.key
                        }
                      })
                    );
                  }}>
                  <span className="motif-body2-default-regular">Download Excel</span>
                  <MotifIcon
                    src={`data:image/svg+xml;base64,${DataCategoryPageIcon.DownloadSvg}`}
                  />
                </MotifButton>
                  )}
              </MotifCardBody>
              <DataCategoryGrid data={dataCategoryGridData} />
            </MotifCard>
          </div>
        )}
      </div>
      {error.length > 0 && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}
    </>
  );
};
export default DataCategoryExplorerPage;
