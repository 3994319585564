import type { SankeyGraph, SankeyLink } from 'd3-sankey';
import { linkHorizontal } from 'd3-shape';
import React from 'react';

type LinkProps = {
  link: SankeyLink<any, any>;
  color: string;
  maxWidth?: number;
  graph?: SankeyGraph<any, any>;
};

const Linking = ({ link, color, maxWidth, graph }: LinkProps): JSX.Element => {
  const horizontalSourceO = (d: any) => {
    const checkSourceExpansion = graph?.nodes.findIndex((node) => {
      return node.isExpanded && node.levelX == d.source.levelX && node.levelY < d.source.levelY;
    });
    const x = 200 + 300 * d.source.levelX;
    const y =
      checkSourceExpansion && checkSourceExpansion > 0
        ? 80 * (d.source.levelY - 1) + 110 + 120
        : 80 * (d.source.levelY - 1) + 110;
    return [x, y] as any;
  };

  const horizontalTargetO = (d: any) => {
    const checkTargetExpansion = graph?.nodes.findIndex((node) => {
      return node.isExpanded && node.levelX == d.target.levelX && node.levelY < d.target.levelY;
    });
    const x = 300 * d.target.levelX;
    const y =
      checkTargetExpansion && checkTargetExpansion > 0
        ? 80 * (d.target.levelY - 1) + 110 + 120
        : 80 * (d.target.levelY - 1) + 110;
    return [x, y] as any;
  };

  const horizontalSource = (d: any) => {
    return [d.source.x1, d.y0] as any;
  };

  const horizontalTarget = (d: any) => {
    return [d.target.x0, d.y1] as any;
  };

  const sankeyLinkHorizontal = () => {
    return linkHorizontal().source(horizontalSource).target(horizontalTarget);
  };

  const sankeyLinkHorizontalO = () => {
    return linkHorizontal().source(horizontalSourceO).target(horizontalTargetO);
  };
  const linkWidth = maxWidth;

  const path: any = maxWidth ? sankeyLinkHorizontalO()(link) : sankeyLinkHorizontal()(link);

  const opacity = 0.3;

  return (
    <path
      d={path}
      style={{
        fill: 'none',
        strokeOpacity: opacity,
        stroke: color,
        strokeWidth: linkWidth && !isNaN(linkWidth) ? linkWidth : 0
      }}>
      <title>
        {link.source.name} -&gt; {link.target.name}
      </title>
    </path>
  );
};

export default Linking;
