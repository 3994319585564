// MSAL imports
import React, { Suspense, useEffect, useState } from 'react';
import { DESKTOP_WIDTH } from 'shared/helpers/constants';
import useLogin from 'shared/hooks/useLogin';

import './App.scss';
import PageLayout from './components/pageLayout/pageLayout.component';
import Routings from './routings';

const App: React.FC = () => {
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(window.innerWidth < DESKTOP_WIDTH);

  const isAuthReady = useLogin();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < DESKTOP_WIDTH) {
        setIsSideNavCollapsed(true);
      } else {
        setIsSideNavCollapsed(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSideNavBar = () => {
    setIsSideNavCollapsed(!isSideNavCollapsed);
  };

  return (
    <>
      <Suspense fallback={<div />}>
        {isAuthReady ? (
          <PageLayout
            toggleSideNavBar={toggleSideNavBar}
            isSideNavCollapsed={isSideNavCollapsed}
            setIsSideNavCollapsed={setIsSideNavCollapsed}>
            <Routings isSideNavCollapsed={isSideNavCollapsed} />
          </PageLayout>
        ) : (
          <p className="loading-text">Authenticating..</p>
        )}
      </Suspense>
    </>
  );
};

export default App;
