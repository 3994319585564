import { MotifChip, MotifIcon } from '@ey-xd/motif-react';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { CommonIcons } from 'images/commonIcons';
import { useMemo } from 'react';
import { INodeChips } from 'shared/models/Sankey';

import styles from './linkNode.module.scss';

interface LinkNodePopupProps {
  nodeText: string;
  isSelected: boolean;
  isExpanded: boolean;
  toolTipData?: string;
  normalChips?: INodeChips[];
  dotChips?: INodeChips[];
  handleNodeClick: any;
  handleOnExpansionClick: any;
  handleChipClick: any;
}

const LinkNode = ({
  nodeText,
  isSelected,
  isExpanded,
  toolTipData,
  normalChips,
  dotChips,
  handleNodeClick,
  handleOnExpansionClick,
  handleChipClick
}: LinkNodePopupProps): JSX.Element => {
  const isChipsInTheNode = useMemo(
    () => (dotChips?.length || normalChips?.length ? true : false),
    [dotChips, normalChips]
  );
  return (
    <>
      <Tooltip title={toolTipData} placement="right" arrow={true}>
        <div
          className={clsx(styles.nodeContent, {
            [styles.expandedNode]: isExpanded,
            [styles.highlightNode]: isSelected
          })}
          onClick={handleNodeClick}
          style={{ WebkitLineClamp: isExpanded ? 'unset' : '2' }}>
          <div
            className={clsx(styles.nameBox, {
              [styles.smallWordClass]: nodeText.length < 27 ? true : false,
              [styles.nameBoxWithBottomChips]: isChipsInTheNode,
              [styles.hideOverflow]: isChipsInTheNode && !isExpanded
            })}>
            {nodeText}
          </div>

          <div className={clsx(styles.bottomBtnContainer)}>
            {dotChips && (
              <>
                {dotChips.map((chipItem: INodeChips) => (
                  <MotifChip
                    key={`dot-chip${chipItem.name}`}
                    style={{ background: '#1a1a23', border: `1px solid ${chipItem.color}` }}
                    className={clsx(styles.nodeChip, styles.dotChip)}
                    title="Chip Title">
                    {chipItem.name}
                  </MotifChip>
                ))}
              </>
            )}

            {normalChips && (
              <>
                {normalChips.map((chipItem: INodeChips) => (
                  <MotifChip
                    type="chip"
                    onClick={handleChipClick(chipItem.name)}
                    key={`normal-chip${chipItem.name}`}
                    style={{ cursor: 'alias !important', background: chipItem.color }}
                    className={clsx(styles.nodeChip, styles.normalChip)}
                    title={`Click here to view the ${chipItem.name} overlapping`}>
                    {chipItem.name}
                  </MotifChip>
                ))}
              </>
            )}
            {nodeText.length >= 28 && (
              <MotifIcon
                onClick={handleOnExpansionClick}
                className={clsx(styles.expansionIcons)}
                src={`data:image/svg+xml;base64,${CommonIcons.expandLineSvg}`}
              />
            )}
          </div>
        </div>
      </Tooltip>
    </>
  );
};
export default LinkNode;
