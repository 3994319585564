import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { loginRequest } from 'msalConfig';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'shared/redux/hooks';
import { getUserAvatar, getUserProfile } from 'shared/redux/slice/userProfileSlice';

const useLogin = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  const [isAuthReady, setIsAuthReady] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error, login]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserProfile());
      dispatch(getUserAvatar());
      setIsAuthReady(true);
    }
  }, [dispatch, isAuthenticated]);

  return isAuthReady;
};

export default useLogin;
