import { styled } from '@mui/system';
import clsx from 'clsx';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { IDonutProps } from 'shared/models/HomePageModel';

import styles from './donutChart.module.scss';

const ChartContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  outline: 'none'
});

const DonutChart = ({ data, metricCount }: IDonutProps): JSX.Element => {
  const total = data.reduce((acc, entry) => acc + entry.value, 0);
  const allZeroValue = data.every((categoryData) => categoryData.value == 0);

  const defaultData = allZeroValue
    ? [{ name: 'Default', value: 1, color: '#23232f' }]
    : data.sort((a, b) => (a.value <= b.value ? 1 : -1));

  return (
    <ChartContainer>
      <ResponsiveContainer height={300}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            stroke="dashed"
            data={defaultData}
            cx="50%"
            cy="50%"
            startAngle={450}
            endAngle={90}
            innerRadius={90}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            labelLine={false}
            label={({ cx, cy, value }) => {
              if (value > 0) {
                const x = cx;
                const y = cy;
                return (
                  <text
                    x={x}
                    y={y}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="#fff"
                    fontSize={40}>
                    {allZeroValue ? 'No Metrics' : metricCount}
                  </text>
                );
              } else {
                return null;
              }
            }}>
            {defaultData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div style={{ marginLeft: '16px' }}>
        <div>
          {data.map((entry, index) => (
            <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
              <span className={clsx('motif-h6', styles.text, styles.percentage)}>
                {allZeroValue
                  ? '00%'
                  : `${Number(((entry.value / total) * 100).toFixed(0)).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    })}%`}
              </span>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: entry.color
                }}></div>
              <span className={clsx('motif-h6', styles.text)}>{`${entry.name}`}</span>
            </div>
          ))}
        </div>
      </div>
    </ChartContainer>
  );
};

export default DonutChart;
