import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_HOMEPAGE_DATA } from 'shared/helpers/constants';
import { HomePageDataModel } from 'shared/models/HomePageModel';
import { homePageRequests } from 'shared/utils/requests/homePage';

import { ResponseError } from '../../core/IError';
import { RootState } from '../store';

interface IHomePageSliceState {
  homePageData: null | HomePageDataModel;
  pending: boolean;
  error?: string;
}

const initialState: IHomePageSliceState = {
  homePageData: null as null | HomePageDataModel,
  pending: true,
  error: ''
};

export const fetchHomePageData = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  GET_HOMEPAGE_DATA,
  async (_, thunkApi) => {
    const response = await homePageRequests.getHomePageData();
    if (response.isSuccess) {
      return response.getValue();
    }
    return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
  }
);

export const homePageSlice = createSlice({
  name: 'homePageSlice',
  initialState,
  reducers: {},
  extraReducers: (reducers) => {
    reducers
      .addCase(fetchHomePageData.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchHomePageData.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(fetchHomePageData.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.homePageData = payload;
      });
  }
});

export const selectHomePageData = (state: RootState) => {
  return state.homePageSlice;
};

// export the reducers
export default homePageSlice.reducer;
