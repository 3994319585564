import clsx from 'clsx';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import React, { useEffect, useState } from 'react';
import { useAppStore } from 'shared/redux/hooks';

import styles from './sapDefinedMetric.module.scss';

const SapDefinedMetric = (): JSX.Element => {
  const [reportingMetrics, setReportingMetrics] = useState<any>(null);

  const {
    sapHomePageData: { metricsCount },
    selectedRegulation
  } = useAppStore().sapHomePageSlice;

  useEffect(() => {
    if (selectedRegulation && metricsCount) {
      const selectedMetrics = metricsCount.filter(
        (value) => value.regulationName.toLowerCase() == selectedRegulation
      );
      if (selectedMetrics[0]?.counts) {
        setReportingMetrics(selectedMetrics[0].counts);
      } else {
        setReportingMetrics({
          primary: 0
        });
      }
    }
  }, [selectedRegulation, metricsCount]);

  return (
    <div className="motif-container no-padding">
      <WidgetHeading
        info={{
          show: true,
          tooltipPlacement: 'bottom',
          tooltipContent: [
            '<b>Primary Disclosure Metrics-</b> Primary level calculations for quantitative disclosures mapped for the selected regulation/framework'
          ]
        }}
        title="Defined Reporting Metrics"
      />

      <div className={clsx(styles.innerContainer, 'motif-row')}>
        <div className={clsx(styles.flexStart, 'motif-col-xs')}>
          <h6 className={clsx(styles.noHeight, 'motif-h6', 'no-padding', styles.metricsHeading)}>
            Primary
          </h6>
          <h1 className={clsx(styles.noHeight, 'motif-h1', 'no-padding')}>
            {reportingMetrics?.primary.toLocaleString('en-US') || 0}
          </h1>
        </div>
      </div>
    </div>
  );
};
export default SapDefinedMetric;
