import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHomePageConfigSliceState {
  selectedRegulation: string;
  selectedIndustrySector: string;
  selectedOAMetrics: string;
  selectedOAMCheckbox: string[];
}

const initialState: IHomePageConfigSliceState = {
  selectedRegulation: '',
  selectedIndustrySector: '',
  selectedOAMetrics: '',
  selectedOAMCheckbox: []
};

export const homePageConfigSlice = createSlice({
  name: 'homePageConfigSlice',
  initialState,
  reducers: {
    setRegulation(state: IHomePageConfigSliceState, action: PayloadAction<string>) {
      state.selectedRegulation = action.payload;
    },
    setIndustrySector(state: IHomePageConfigSliceState, action: PayloadAction<string>) {
      state.selectedIndustrySector = action.payload;
    },
    setOAMetrics(state: IHomePageConfigSliceState, action: PayloadAction<string>) {
      state.selectedOAMetrics = action.payload;
    },
    setOAMCheckbox(state: IHomePageConfigSliceState, action: PayloadAction<string[]>) {
      state.selectedOAMCheckbox = action.payload;
    }
  }
});

// export the actions
export const { setRegulation, setIndustrySector, setOAMetrics, setOAMCheckbox } =
  homePageConfigSlice.actions;
// export the reducers
export default homePageConfigSlice.reducer;
