import {
  Configuration,
  LogLevel,
  PopupRequest,
  PublicClientApplication
} from '@azure/msal-browser';
import { SSO_CLIENT_ID, SSO_TENANT_ID } from 'shared/helpers/constants';

export const msalConfig: Configuration = {
  auth: {
    clientId: SSO_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${SSO_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'memoryStorage',
    storeAuthStateInCookie: true,
    secureCookies: true
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const AUTH_SCOPES = {
  PROFILE: 'profile',
  OPENID: 'openid',
  EMAIL: 'email',
  USER_READ: 'User.Read'
};

export const loginRequest: PopupRequest = {
  scopes: [AUTH_SCOPES.PROFILE, AUTH_SCOPES.OPENID, AUTH_SCOPES.EMAIL, AUTH_SCOPES.USER_READ]
};

export const apiRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_API_SCOPES ? process.env.REACT_APP_API_SCOPES : '']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const mandatoryLogoutTimer = 1000 * 60 * 60 * 1; // 1 hours
