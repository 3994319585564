import { MotifTruncate } from '@ey-xd/motif-react';
import { ThemeProvider, createTheme } from '@mui/material';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import React, { useMemo } from 'react';
import { DataCategoryGridData } from 'shared/redux/slice/dataCategoryPageSlice';

interface DataCategoryGridProps {
  data?: DataCategoryGridData[];
}

const ellipsisText = (value: string) => {
  return (
    <MotifTruncate
      maxLines={2}
      className="dataGridCellValue"
      tooltipProps={{ contentClassName: 'tooltipContent' }}>
      {value}
    </MotifTruncate>
  );
};

const DataCategoryGrid: React.FC<DataCategoryGridProps> = ({ data = [] }) => {
  const columnDef = useMemo<MRT_ColumnDef<DataCategoryGridData>[]>(() => {
    return [
      {
        accessorFn: (row) => ellipsisText(row.pillar),
        header: 'Sustainability Pillar'
      },
      {
        accessorFn: (row) => ellipsisText(row.theme),
        header: 'Theme'
      },
      {
        accessorFn: (row) => ellipsisText(row.topic),
        header: 'Reporting Framework Section'
      },
      {
        accessorFn: (row) => ellipsisText(row.subTopic),
        header: 'Reporting Framework Sub-Section'
      },
      {
        accessorFn: (row) => ellipsisText(row.primaryDisclosureMetrics),
        header: 'Primary Disclosures'
      },
      {
        accessorFn: (row) => ellipsisText(row.constituentMetrics),
        header: 'Intermediate Attributes'
      },
      { accessorFn: (row) => ellipsisText(row.coreData), header: 'Core Data Metrics' }
    ];
  }, []);

  return (
    <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
      <MaterialReactTable
        data={data}
        columns={columnDef}
        initialState={{ density: 'spacious' }}
        positionExpandColumn="last"
        enableTopToolbar={false}
        enableSorting={false}
        enableColumnActions={false}
      />
    </ThemeProvider>
  );
};
export default DataCategoryGrid;
