import React from 'react';
import { MotifIcon } from '@ey-xd/motif-react';
import styles from './roundIconContainer.module.scss';

interface RoundIconContainerProps {
  icon: string;
  customStyle?: any;
}

const RoundIconContainer = ({ icon, customStyle }: RoundIconContainerProps): JSX.Element => {
  return (
    <div className={styles.roundIcon} {...(customStyle && { style: customStyle })}>
      <MotifIcon className={styles.icon} src={`data:image/svg+xml;base64,${icon}`} />
    </div>
  );
};
export default RoundIconContainer;
