import {
  MotifAvatar,
  MotifDropdown,
  MotifDropdownItem,
  MotifHeader,
  MotifHeaderLogo,
  MotifIcon,
  MotifVerticalNavigationHeaderButton
} from '@ey-xd/motif-react';
import { CommonIcons } from 'images/commonIcons';
import { HeaderIcons } from 'images/headerIcons';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_NAME } from 'shared/helpers/constants';
import { useAppStore } from 'shared/redux/hooks';
import { logoutService } from 'shared/utils/utils';

import styles from './header.module.scss';

interface HeaderProps {
  toggleSideNavBar: () => void;
}

const Header = ({ toggleSideNavBar }: HeaderProps): JSX.Element => {
  const [isLogoutClicked, setIsLogoutClicked] = useState<boolean>(false);
  const { name, emailId, avatar } = useAppStore().userProfileSlice;
  const currentPath = useLocation().pathname.includes('/saphome') ? '/saphome' : '/';
  const [isProfileSectionVisible, setProfileSectionVisible] = useState<boolean>(false);
  const toggleProfileSection = () => {
    setProfileSectionVisible(!isProfileSectionVisible);
  };

  const handleLogout = async () => {
    setIsLogoutClicked(true);
    await logoutService();
    setIsLogoutClicked(false);
  };
  const handleDropdownBlur = () => {
    setProfileSectionVisible(false);
  };

  return (
    <div>
      <MotifHeader
        appHeaderName={APP_NAME}
        fixed
        iconsContainer={
          <>
            <MotifDropdown
              className="profileHeaderContainer"
              handleClickOutside={handleDropdownBlur}
              ariaLabelledby="logout-drop-down"
              id="logout-dropdown"
              trigger={
                <MotifAvatar
                  onClick={toggleProfileSection}
                  className={`${styles.userAvatar} ${isProfileSectionVisible ? styles.visibleProfile : ''}`}
                  description="UserName name.use@ey.com"
                  src={avatar}
                  userName={name}
                />
              }
              open={isProfileSectionVisible}>
              <MotifDropdownItem type="container">
                <div
                  style={{
                    display: 'flex',
                    gap: '12px'
                  }}>
                  <MotifAvatar
                    className="motif-avatar-size-large"
                    description="UserName name.use@ey.com"
                    src={avatar}
                    userName={name}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <span className="motif-body1-default-regular">{name}</span>
                    <span className="motif-body1-default-light">{emailId}</span>
                  </div>
                </div>
              </MotifDropdownItem>
              <span className="motif-dropdown-horizontal-divider" />
              <MotifDropdownItem
                aria-label="Log out"
                onClick={() => {
                  if (!isLogoutClicked) {
                    handleLogout();
                  }
                }}
                className={styles.logOut}>
                <MotifIcon src={`data:image/svg+xml;base64,${CommonIcons.logoutSvg}`} />
                <span>Log out</span>
              </MotifDropdownItem>
            </MotifDropdown>
          </>
        }
        leftComponents={<MotifVerticalNavigationHeaderButton onClick={toggleSideNavBar} />}
        logo={
          <MotifHeaderLogo>
            <a href={currentPath}>
              <MotifIcon
                aria-hidden="true"
                src={`data:image/svg+xml;base64,${HeaderIcons.eyLogo}`}
              />
              <span className="motif-visually-hidden">EY Logo - Home</span>
            </a>
          </MotifHeaderLogo>
        }
      />
    </div>
  );
};

export default Header;
