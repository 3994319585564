import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MotifTheme } from '@ey-xd/motif-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';

import App from './App';
import { msalInstance } from './msalConfig';
import { store } from './shared/redux/store';

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload) {
      const payload = event.payload as AuthenticationResult; // Cast the payload to AuthenticationResult
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Provider store={store}>
          <MotifTheme variant="dark" />
          <App />
        </Provider>
      </BrowserRouter>
    </MsalProvider>
  );
});
