import { MotifTruncate } from '@ey-xd/motif-react';
import clsx from 'clsx';
import * as d3 from 'd3';
import React, { useEffect, useState } from 'react';
import { Tree } from 'shared/redux/slice/dataCategoryPageSlice';

import styles from './treeMap.module.scss';

type TreemapProps = {
  width: number;
  height: number;
  data: Tree;
  updateTreeData: any;
  updated: string;
  level: string;
};

function treemapSliceDice(
  root: d3.HierarchyRectangularNode<any>,
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  padding: number
) {
  if (root.children && root.children.length) {
    // Sort children based on size
    root.children.sort(
      (a: d3.HierarchyRectangularNode<any>, b: d3.HierarchyRectangularNode<any>) => {
        if (a.value && b.value) return b.value - a.value;
        else return 0;
      }
    );

    // Calculate total size
    const total = root.children.reduce((sum, child) => sum + (child.value ? child.value : 0), 0);

    // Iterate over children
    let current = x0;
    let countSmaller = 0;
    let smallerTotal = 0;
    root.children.map((leaf) => {
      const size = ((leaf.value ? leaf.value : 0) / total) * (x1 - x0);
      if (size < 100) {
        smallerTotal += leaf.value ? leaf.value : 0;
        countSmaller++;
      }
    });
    for (const child of root.children) {
      let size =
        ((child.value ? child.value : 0) / (total - smallerTotal)) * (x1 - x0 - countSmaller * 100);
      size = Math.max(size, 100);
      treemapSliceDice(
        child,
        current + padding,
        y0 + padding,
        current + size - padding,
        y1 - padding,
        padding
      );
      current += size;
    }
  }

  root.x0 = x0;
  root.y0 = y0;
  root.x1 = x1;
  root.y1 = y1;
}

export const Treemap = ({ width, height, data, updateTreeData, updated, level }: TreemapProps) => {
  const treeGenerator =
    level != 'level3'
      ? d3.treemap<Tree>().size([width, height])
      : d3.treemap<Tree>().tile(d3.treemapResquarify).padding(15).size([width, height]);
  const [root, setRoot] = useState<d3.HierarchyRectangularNode<Tree>>(
    treeGenerator(d3.hierarchy(data).sum((d) => d.value))
  );

  useEffect(() => {
    setRoot(treeGenerator(d3.hierarchy(data).sum((d) => d.value)));
    if (level != 'level3') treemapSliceDice(root, 0, 0, width, height, 10);
  }, [data]);

  if (level != 'level3') treemapSliceDice(root, 0, 0, width, height, 10);

  const alreadySelected = () => {
    const selectedLeaf = root.leaves().find((leaf) => {
      return leaf.data.isSelect;
    });
    return selectedLeaf?.data.key;
  };
  const handleLeafSelection = (leaf: d3.HierarchyRectangularNode<Tree>) => {
    const alreadySelectedKey = alreadySelected();
    if (alreadySelectedKey) {
      if (alreadySelectedKey == leaf.data.key) {
        return updateTreeData(data, leaf.data.key, leaf.data.key, false, level);
      }
      return updateTreeData(data, leaf.data.key, alreadySelectedKey, true, level);
    } else {
      return updateTreeData(data, leaf.data.key, leaf.data.key, true, level);
    }
  };

  return (
    <div>
      <svg width={width} height={height} className={styles.container}>
        {root.leaves().map((leaf) => {
          return (
            <g key={leaf.data.key} className={styles.rectangle}>
              <foreignObject
                onClick={() => handleLeafSelection(leaf)}
                x={leaf.x0}
                y={leaf.y0}
                width={leaf.x1 - leaf.x0}
                height={leaf.y1 - leaf.y0}>
                <div
                  style={{
                    backgroundColor: leaf.data.color,
                    border: leaf.data.isSelect
                      ? '4px solid var(--Neutrals-Light-theme-00-White, #fff)'
                      : '',
                    height: 'inherit'
                  }}
                  className={clsx(
                    'opacity-80',
                    'hover:opacity-100',
                    updated
                      ? leaf.data.isSelect
                        ? styles.foreignObjectSelected
                        : styles.foreignObjectNotSelected
                      : styles.foreignObject
                  )}>
                  <div
                    className={clsx(
                      leaf.x1 - leaf.x0 >= 100
                        ? styles.textContainer
                        : styles.textContainerFlexStart,
                      (level == 'level0' && leaf.data.name == 'No Data to display') ||
                        (level != 'level1' && level != 'level0')
                        ? styles.textColorGreaterLevel
                        : styles.textColorInitialLevel
                    )}>
                    <MotifTruncate
                      maxLines={2}
                      className="dataTreemap"
                      maxWidth={0}
                      tooltipProps={{ placement: 'top' }}>
                      {leaf.data.name}
                    </MotifTruncate>
                  </div>
                  <div className={styles.countContainer}>{leaf.data.value}</div>
                </div>
              </foreignObject>
            </g>
          );
        })}
      </svg>
    </div>
  );
};
