import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface IPageLoader {
  pageLoader: boolean;
}

const initialState: IPageLoader = {
  pageLoader: false
};

export const pageLoaderSlice = createSlice({
  name: 'pageLoader',
  initialState: initialState,
  reducers: {
    showLoader: (state: IPageLoader) => {
      state.pageLoader = true;
      return state;
    },
    closeLoader: (state: IPageLoader) => {
      state.pageLoader = false;
      return state;
    }
  }
});

export const selectPageLoader = (state: RootState) => {
  return state.pageLoaderSlice;
};
export const loaderActions = pageLoaderSlice.actions;
export default pageLoaderSlice.reducer;
