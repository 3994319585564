import { TreeNode } from 'shared/redux/slice/dataCategoryPageSlice';

export const rootData: TreeNode = {
  key: 'R1',
  type: 'node',
  name: 'No Data to display',
  value: 0,
  color: '#E5DCF1',
  isSelect: false,
  children: []
};

export const PillarColorMap = new Map([
  ['level0-Environmental', '#4A279F'],
  ['level0-Social', '#FF735E'],
  ['level0-Governance', '#E180C0'],
  ['level0-General', '#D4C4EA'],
  ['level1-Environmental', '#724BC3'],
  ['level2-Environmental', '#C2ABE3'],
  ['level3-Environmental', '#E5DCF1']
]);

export const excelURL = process.env.REACT_APP_DATA_CATEGORY_CSV_URL;
