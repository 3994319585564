import clsx from 'clsx';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import { setRegulation } from 'shared/redux/slice/homePageConfigSlice';
import { fetchHomePageData } from 'shared/redux/slice/homePageDataSlice';
import { loaderActions } from 'shared/redux/slice/pageLoaderSlice';
import {
  fetchNonSAPHomePageData,
  fetchSapHomePageData,
  setRegulationSelected
} from 'shared/redux/slice/sapHomePageSlice';
import { formatArrayForSelectBox } from 'shared/utils/utils';

import styles from './frameworkAndRegulation.module.scss';

interface FrameworkAndRegulationProps {
  frameworksRegulations: string[];
  selectedRegulation: string | undefined;
  setSelectRegulation: (regulationName: string) => void;
}

const FrameworkAndRegulation: React.FC<FrameworkAndRegulationProps> = ({
  frameworksRegulations,
  selectedRegulation = '',
  setSelectRegulation
}) => {
  return (
    <div className="motif-container no-padding">
      <WidgetHeading
        showSelectBox={{
          selectedValue: selectedRegulation,
          data: formatArrayForSelectBox(frameworksRegulations),
          onchange: (value: string) => {
            setSelectRegulation(value);
          }
        }}
        title="Reporting Frameworks"
      />
      <div className={clsx(styles.innerContainer, 'motif-row')}>
        <div className="motif-col-xs-4">
          <h1 className="motif-h1">{frameworksRegulations?.length || 0}</h1>
        </div>
      </div>
    </div>
  );
};
export default FrameworkAndRegulation;

export const FrameworkAndRegulationContainerSap = () => {
  const dispatch = useAppDispatch();
  const {
    sapHomePageData: { regulations },
    selectedRegulation
  } = useAppStore().sapHomePageSlice;

  useEffect(() => {
    if (regulations.length) {
      if (!selectedRegulation) {
        dispatch(setRegulationSelected(regulations[0].toLowerCase()));
      }
    }
  }, [regulations]);

  useEffect(() => {
    if (!regulations.length) {
      dispatch(fetchNonSAPHomePageData()).finally(() => {
        dispatch(loaderActions.showLoader());
        // this will load the data for first time
        dispatch(fetchSapHomePageData()).finally(() => {
          dispatch(loaderActions.closeLoader());
        });
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setRegulationSelected(''));
    };
  }, []);

  const setSelectRegulation = (value: string) => {
    dispatch(setRegulationSelected(value));
  };

  return (
    <FrameworkAndRegulation
      frameworksRegulations={regulations}
      selectedRegulation={selectedRegulation}
      setSelectRegulation={setSelectRegulation}
    />
  );
};

export const FrameworkAndRegulationContainer = () => {
  const dispatch = useAppDispatch();
  const { selectedRegulation } = useAppStore().homePageConfigSlice;
  const { homePageData } = useAppStore().homePageSlice;

  useEffect(() => {
    if (homePageData?.frameworksRegulations.length) {
      dispatch(loaderActions.closeLoader());
      if (!selectedRegulation) {
        dispatch(setRegulation(homePageData?.frameworksRegulations[0]));
      }
    }
  }, [homePageData]);

  useEffect(() => {
    if (!homePageData) {
      dispatch(loaderActions.showLoader());
      dispatch(fetchHomePageData()).then(() => {
        dispatch(loaderActions.closeLoader());
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setRegulation(''));
    };
  }, []);

  const setSelectRegulation = (value: string) => {
    dispatch(setRegulation(value));
  };

  return (
    <FrameworkAndRegulation
      frameworksRegulations={homePageData?.frameworksRegulations || []}
      selectedRegulation={selectedRegulation}
      setSelectRegulation={setSelectRegulation}
    />
  );
};
