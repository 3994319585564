import { MotifCard } from '@ey-xd/motif-react';
import clsx from 'clsx';

import { FrameworkAndRegulationContainerSap } from 'components/pageContents/molecules/home/frameworkAndRegulation/frameworkAndRegulation.component';
import { OverlapAnalysisContainerSap } from 'components/pageContents/molecules/home/overlapAnalysis/overlapAnalysis.component';
import { MetricExplorerContainerSap } from 'components/pageContents/molecules/home/metricExplorer/metricExplorer.component';

import styles from './sapHomePage.module.scss';
import SapDefinedMetric from 'components/pageContents/molecules/saphome/sapDefinedMetric/sapDefinedMetric.component';

const SapHomePage = (): JSX.Element => {
  return (
    <div className="motif-container">
      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className={clsx('motif-col-lg-6', 'motif-col-md', 'motif-col-xs-2', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <FrameworkAndRegulationContainerSap />
          </MotifCard>
        </div>
        <div className={clsx('motif-col-lg-6', 'motif-col-md', 'motif-col-xs-2', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <SapDefinedMetric />
          </MotifCard>
        </div>
      </div>
      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className={clsx('motif-col-lg-6', 'motif-col-md-3', 'motif-col-xs-4', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <OverlapAnalysisContainerSap />
          </MotifCard>
        </div>
        <div className={clsx('motif-col-lg-6', 'motif-col-md-3', 'motif-col-xs-4', 'widgetBox')}>
          <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
            <MetricExplorerContainerSap />
          </MotifCard>
        </div>
      </div>
    </div>
  );
};
export default SapHomePage;
