import {
  MotifIcon,
  MotifLabel,
  MotifOption,
  MotifProgressLoader,
  MotifSelect
} from '@ey-xd/motif-react';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation';
import clsx from 'clsx';
import DataSourcingPopup from 'components/pageContents/molecules/dataSourcing/dataSourcingPopup/dataSourcingPopup.component';
import DataSourcingTopicCard from 'components/pageContents/molecules/dataSourcing/dataSourcingTopicCard/dataSourcingTopicCard.component';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import React, { useEffect, useState } from 'react';
import { TopicIconMap } from 'shared/data/dataSourcingPage';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import {
  fetchDataSourcingDetails,
  selectDataSource,
  selectPillar,
  selectRegulation,
  selectTopic
} from 'shared/redux/slice/dataSourcingPageSlice';

import styles from './sapDataSourcingPage.module.scss';

const pageName = 'Data Sourcing';

interface DataSourcingProps {
  isSideNavCollapsed: boolean;
}

const DataSourcingPage = ({ isSideNavCollapsed }: DataSourcingProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    dataSourcingList,
    selectedDataSource,
    regulationList,
    selectedRegulation,
    selectedPillar,
    selectedTopic,
    isRegulationLoading
  } = useAppStore().dataSourcingPageSlice;
  const [error, setError] = useState<string>('');

  const handleDataSourcingChange = (value: string) => {
    dispatch(selectDataSource(value));
  };

  const handleRegulationChange = (value: string) => {
    if (value) {
      dispatch(selectRegulation(value));
    }
  };

  useEffect(() => {
    dispatch(fetchDataSourcingDetails()).then((data) => {
      if (data.type.indexOf('rejected') >= 0) {
        setError('Error occured while fetching Data sourcing system details');
      }
    });
    return () => {
      dispatch(selectDataSource());
      dispatch(selectRegulation());
      dispatch(selectPillar());
      dispatch(selectTopic());
    };
  }, [dispatch]);

  if (selectedTopic) {
    return <DataSourcingPopup isSideNavCollapsed={isSideNavCollapsed} />;
  }

  const getDataSourcingTabIcons = (pillarName: string) => {
    return TopicIconMap.get(pillarName || '');
  };
  const handleErrorModalClose = () => {
    setError('');
  };

  return (
    <div className="motif-container">
      <MotifProgressLoader show={isRegulationLoading} fullscreen />
      <div className={'widgetSpacing'}>
        <div className="pageHeading">
          <h2>{pageName}</h2>
        </div>
      </div>
      <div className={clsx('widgetSpacing', 'motif-row')}>
        <div className="motif-col-">
          <MotifLabel className={styles.selectboxLabel}>Data Sourcing Systems</MotifLabel>
          <MotifSelect
            visibleOptions={3}
            value={selectedDataSource?.dataSourcingName}
            className={styles.selectbox}
            onChange={handleDataSourcingChange}>
            {dataSourcingList?.map((dataSourcingName) => (
              <MotifOption key={dataSourcingName} value={dataSourcingName}>
                {dataSourcingName}
              </MotifOption>
            ))}
          </MotifSelect>
        </div>
        {selectedDataSource && (
          <div className="motif-col-">
            <MotifLabel className={styles.selectboxLabel}>Reporting Framework</MotifLabel>
            <MotifSelect
              visibleOptions={3}
              value={selectedRegulation?.name}
              className={styles.selectbox}
              onChange={handleRegulationChange}>
              {regulationList?.map((regulationName) => (
                <MotifOption key={regulationName} value={regulationName}>
                  {regulationName}
                </MotifOption>
              ))}
            </MotifSelect>
          </div>
        )}
      </div>
      {selectedRegulation && (
        <div className={clsx('widgetSpacing')}>
          <MotifTabNavigation
            className="motif-tab-fullwidth"
            defaultActiveKey={selectedRegulation.pillars.findIndex(
              (item) => item.pillarName === selectedPillar?.pillarName
            )}>
            {selectedRegulation.pillars
              .filter((item) => item.topics.length > 0)
              .map((item, index) => (
                <MotifTabControl
                  onClick={() => {
                    dispatch(
                      selectPillar({
                        key: index,
                        name: item.pillarName
                      })
                    );
                  }}
                  key={index}>
                  <MotifIcon
                    src={`data:image/svg+xml;base64,${getDataSourcingTabIcons(item.pillarName)}`}
                  />
                  {item.pillarName}
                </MotifTabControl>
              ))}
          </MotifTabNavigation>
          <div className="motif-tab-content motif-row">
            {selectedRegulation.pillars
              .find((item) => item.pillarName === selectedPillar?.pillarName)
              ?.topics.map((item) => {
                return (
                  <div
                    key={item.name}
                    className={`motif-col-lg-3 motif-col-md-4 motif-col-sm-6 motif-col-xs-12 ${styles.topicCardWrapper}`}>
                    <DataSourcingTopicCard
                      name={item.name}
                      id={item.id}
                      onClick={({ name, id }) => {
                        dispatch(selectTopic({ name, id }));
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {error.length > 0 && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}
    </div>
  );
};

export default DataSourcingPage;
