/* eslint-disable  @typescript-eslint/no-unused-vars */
import HandleZoom from 'components/pageContents/atoms/handleZoom/handleZoom.component';
import {
  type SankeyExtraProperties,
  type SankeyGraph,
  type SankeyLink,
  type SankeyNode,
  sankey as d3sankey
} from 'd3-sankey';
import React, { type ReactNode, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { ZoomTypes } from 'shared/models/LineagePageModel';

interface SankeyProps<N, L> {
  data: {
    nodes: SankeyNode<SankeyExtraProperties, SankeyExtraProperties>[];
    links: SankeyLink<SankeyExtraProperties, SankeyExtraProperties>[];
  };
  width?: number;
  height?: number;
  nodeWidth: number;
  nodePadding: number;
  children?: (sankey: {
    graph: SankeyGraph<SankeyExtraProperties, SankeyExtraProperties>;
  }) => ReactNode;
}

const SankeyComponent = <N, L>({
  data,
  width,
  height,
  nodeWidth,
  nodePadding,
  children
}: SankeyProps<N, L>): JSX.Element => {
  const [zoomScale, setZoomScale] = useState<number>(100);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const sankeyWidth = width ? width : windowWidth - 100;
  const sankeyHeight = height ? height : windowHeight - 100;

  const [graph, setGraph] = useState<SankeyGraph<SankeyExtraProperties, SankeyExtraProperties>>({
    nodes: [],
    links: []
  });

  const applyZoomEffect = (type: ZoomTypes, scale: number) => {
    setZoomScale(scale);
  };

  useEffect(() => {
    return () => {
      setGraph({ nodes: [], links: [] });
    };
  }, []);

  useEffect(() => {
    setGraph(
      d3sankey<SankeyExtraProperties, SankeyExtraProperties>()
        .nodeWidth(nodeWidth)
        .nodePadding(nodePadding)
        .extent([
          [0, 0],
          [sankeyWidth, sankeyHeight - 50]
        ])(data)
    );
  }, [nodePadding, nodeWidth, sankeyWidth, sankeyHeight, data]);

  return (
    <>
      {children && (
        <svg
          height={sankeyHeight}
          id="sankey-svg"
          style={{
            overflow: 'auto',
            minWidth: sankeyWidth + 300,
            transformOrigin: 'left top',
            transform: `scale(${zoomScale / 100})`
          }}>
          {children({ graph })}
        </svg>
      )}

      <HandleZoom zoomFunction={applyZoomEffect} />
    </>
  );
};

export default SankeyComponent;
