import { MotifCard, MotifProgressLoader } from '@ey-xd/motif-react';
import clsx from 'clsx';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import { Linking, Node, Sankey } from 'components/pageContents/molecules/lineage/Sankey';
import MetricsCountWidget from 'components/pageContents/molecules/lineage/metricsCountWidget/metricsCountWidget.component';
import RLModalWindow from 'components/pageContents/molecules/lineage/rlModalWindow/rlModalWindow.component';
import { SankeyExtraProperties, SankeyLink, SankeyNode } from 'd3-sankey';
import { LineagePageIcons } from 'images/lineagePageIcons';
import React, { useEffect, useState } from 'react';
import { REGULATORY_LINEAGE_MAX_LEVEL_X } from 'shared/helpers/constants';
import { ExtraNodeProperties, RegulatoryLineagePopupData } from 'shared/models/Sankey';
import { useAppDispatch, useAppSelector } from 'shared/redux/hooks';
import {
  fetchRLPageData,
  rlPageSliceActions,
  selectRLPageData
} from 'shared/redux/slice/RegulatoryLineageDataSlice';

import styles from './regulatoryLineagePage.module.scss';

const RegulatoryLineagePage = (): JSX.Element => {
  const pageName = 'Regulatory Lineage';

  // Graph data used for lineage page
  const [graphData, setSelectedGraphData] = useState<{
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[];
  }>({ nodes: [{ key: '0', name: 'Reporting Framework', levelX: 0, levelY: 0 }], links: [] });

  const [error, setError] = useState<string>('');

  // For Width and Height as per node data
  const [maxLevelY, setMaxLevelY] = useState<number>(0);

  // Node Data from API
  const [nodeData, setNodeData] = useState<
    SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[]
  >([]);

  const [maxLevelXSelected, setMaxLevelXSelected] = useState<number>(-1);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<RegulatoryLineagePopupData | null>();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);

  const MetricsTypes = {
    primaryDisclosureMetrics: 'Primary Disclosures',
    secondaryMetrics: 'Intermediate Attributes',
    coreDataAttributes: 'Core Data Attributes'
  };

  const dispatch = useAppDispatch();
  const { metricCount, RLPageData } = useAppSelector(selectRLPageData);

  useEffect(() => {
    if (Object.keys(RLPageData).length > 0) {
      setLoading(true);
      const mutableObject = JSON.parse(JSON.stringify(RLPageData[0]));
      setNodeData(mutableObject);
      const zeroLevelNodes = mutableObject.filter((node: any) => {
        return node.levelX == 0;
      });
      setSelectedGraphData({ nodes: zeroLevelNodes, links: [] });
      let levelX = 0;
      let levelY = 0;
      mutableObject.map((node: any) => {
        levelX = node.levelX > levelX ? node.levelX : levelX;
        levelY = node.levelY > levelY ? node.levelY : levelY;
      });
      setMaxLevelY(levelY);
      setLoading(false);
    }
  }, [Object.keys(RLPageData).length]);

  useEffect(() => {
    const fetchDataThunk = async () => {
      if (Object.keys(RLPageData).length == 0) {
        try {
          setLoading(true);
          setInitialLoader(true);
          const result = await dispatch(
            fetchRLPageData({ columnId: 0, nodeId: 0, parentKey: '0', selectedNodes: [] })
          );
          if (result.type.endsWith('rejected')) {
            throw new Error('Failed to fetch data');
          }
          setInitialLoader(false);
        } catch (error) {
          setLoading(false);
          setInitialLoader(false);
          setError('Error Occurred');
        }
      }
    };
    fetchDataThunk();
  }, []);

  const onNodeClick = async (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[],
    currentNode: any,
    levelX: number
  ) => {
    // check if loading is true then return
    if (loading) return;

    setMaxLevelXSelected(currentNode.levelX);
    // if (currentNode?.extraData?.count) {
    //   dispatch(
    //     rlPageSliceActions.updateMetricCount({
    //       primary: Number(currentNode?.extraData?.count?.primary || 0),
    //       secondary: Number(currentNode?.extraData?.count?.secondary || 0),
    //       coreData: Number(currentNode?.extraData?.count?.coreData || 0)
    //     })
    //   );
    // }
    setLoading(true);
    if (currentNode.levelX == REGULATORY_LINEAGE_MAX_LEVEL_X) {
      const extraData = await dispatch(
        fetchRLPageData({
          columnId: currentNode.levelX + 1,
          nodeId: currentNode.key,
          parentKey: currentNode.key,
          selectedNodes: nodes.filter((value) => {
            return value.isSelected;
          })
        })
      );
      setLoading(false);
      const modalData = {
        ...{
          attributeName: '',
          csrdAny: '',
          esgPillar: '',
          esrsE1: '',
          generalCalculation: '',
          subSubDomain: '',
          unitOfMeasure: '',
          id: ''
        },
        ...extraData.payload
      };
      const selectedNode = nodes.filter((value) => {
        return value.isSelected;
      });
      selectedNode.map((node: any) => {
        switch (node.levelX) {
          case 0:
            //Can pick from path RegulationName's value
            modalData.csrdAny = node.name;
            break;
          case 1:
            //Can pick from path; Pillar's value
            modalData.esgPillar = node.name;
            break;
          case 2:
            //Can pick from path; Topic's value
            modalData.esrsE1 = node.name;
            break;
          case 6:
            //Can pick from path; Pillar's value
            modalData.attributeName = node.name;
            break;
          default:
            console.log('default');
        }
      });
      const fullNode = [...nodeData];
      let levelNX = 0;
      let levelNY = 0;
      fullNode.map((node: any) => {
        levelNX = node.levelX > levelNX ? node.levelX : levelNX;
        levelNY = node.levelY > levelNY ? node.levelY : levelNY;
      });
      setMaxLevelY(levelNY);
      setNodeData(fullNode);
      const newNodeToDraw = [...nodes];
      await setSelectedGraphData({
        nodes: newNodeToDraw,
        links: links
      });
      setModalData(modalData);
      setModalOpen(true);
    } else {
      let inComingNodes: any = [];
      let nextLevelData: any = [];
      try {
        nextLevelData = await dispatch(
          fetchRLPageData({
            columnId: currentNode.levelX + 1,
            nodeId: currentNode.key,
            parentKey: currentNode.key,
            selectedNodes: nodes.filter((value) => {
              return value.isSelected;
            })
          })
        );
        const { payload } = nextLevelData;
        inComingNodes = JSON.parse(JSON.stringify(payload.data[currentNode.levelX + 1]));
        const nextLevelNode = inComingNodes.filter((node: any) => {
          return node.levelX == levelX + 1;
        });
        let filteredNode = [...nodeData];
        const nextLevelNodeCheck = nodeData.findIndex((value) => {
          return value.levelX == levelX + 1;
        });
        if (nextLevelNodeCheck >= 0) {
          filteredNode = nodeData.filter((value) => {
            return value.levelX != levelX + 1;
          });
        }
        const fullNode = [...filteredNode, ...inComingNodes];
        let levelNX = 0;
        let levelNY = 0;
        fullNode.map((node: any) => {
          levelNX = node.levelX > levelNX ? node.levelX : levelNX;
          levelNY = node.levelY > levelNY ? node.levelY : levelNY;
        });
        setMaxLevelY(levelNY);
        setNodeData(fullNode);
        const newNodeToDraw = [...nodes, ...nextLevelNode];
        await setSelectedGraphData({
          nodes: newNodeToDraw,
          links: links
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const latestNodes = nodes.filter((data) => {
          return data.levelX <= currentNode.levelX;
        });
        const newNodeToDraw = [...latestNodes];
        const latestLink = links.filter((data: any) => {
          return (
            typeof data.source == 'number' ||
            typeof data.target == 'number' ||
            data.source.levelX <= currentNode.levelX - 1
          );
        });
        await setSelectedGraphData({
          nodes: newNodeToDraw,
          links: latestLink
        });
        setError('Error Occurred');
      }
    }

    if (levelX != undefined) setMaxLevelXSelected(levelX);
  };

  const updateSearchResult = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const updateNodeExpansion = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setError('');
  };

  useEffect(() => {
    return () => {
      setModalOpen(false);
      dispatch(
        rlPageSliceActions.updateMetricCount({
          primary: 0,
          secondary: 0,
          coreData: 0
        })
      );
    };
  }, []);

  return (
    <>
      <div className={clsx('motif-container', styles.borderBottom)}>
        <div className={clsx('widgetSpacing', 'motif-row')}>
          <div className="pageHeading">
            <h2>{pageName}</h2>
          </div>
        </div>

        <div className={clsx('widgetSpacing', 'motif-row')} style={{ paddingBottom: '15px' }}>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard
              className={clsx(styles.smallWidgets, 'customMotifCard')}
              data-testid="metric-primary">
              <MetricsCountWidget
                count={metricCount.primary}
                metricsName={MetricsTypes.primaryDisclosureMetrics}
                icon={LineagePageIcons.onePointCircleSvg}
              />
            </MotifCard>
          </div>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard
              className={clsx(styles.smallWidgets, 'customMotifCard')}
              data-testid="metric-secondary">
              <MetricsCountWidget
                count={metricCount.secondary}
                metricsName={MetricsTypes.secondaryMetrics}
                icon={LineagePageIcons.twoPointsCircleSvg}
              />
            </MotifCard>
          </div>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard
              className={clsx(styles.smallWidgets, 'customMotifCard')}
              data-testid="metric-coreData">
              <MetricsCountWidget
                count={metricCount.coreData}
                metricsName={MetricsTypes.coreDataAttributes}
                icon={LineagePageIcons.xrayViewSvg}
              />
            </MotifCard>
          </div>
        </div>
      </div>

      <div className="motif-container" style={{ display: 'grid' }}>
        <div className={clsx('widgetSpacing', 'motif-row')} style={{ overflow: 'auto' }}>
          {graphData && (
            <Sankey<ExtraNodeProperties, SankeyExtraProperties>
              data={graphData}
              nodeWidth={150}
              height={110 + maxLevelY * 80}
              width={
                200 * (REGULATORY_LINEAGE_MAX_LEVEL_X + 1) + 100 * REGULATORY_LINEAGE_MAX_LEVEL_X
              }
              nodePadding={40}>
              {({ graph }) => {
                return (
                  <g>
                    {graph.links &&
                      graph.links.map((link: any, i) => (
                        <Linking
                          key={`sankey-link-${i}`}
                          link={link}
                          color={'#DA5BB3'}
                          maxWidth={30}
                          graph={graph}
                        />
                      ))}
                    {graph &&
                      graph.nodes.map((node, i) => {
                        return (
                          <Node<ExtraNodeProperties, SankeyExtraProperties>
                            key={`sankey-node-${i}`}
                            index={i}
                            currentNode={node}
                            height={60}
                            width={200}
                            graph={graph}
                            maxLevelXSelected={maxLevelXSelected}
                            apiNodeData={nodeData}
                            maxLevelX={REGULATORY_LINEAGE_MAX_LEVEL_X}
                            onNodeClicked={onNodeClick}
                            updateSearchOnView={updateSearchResult}
                            updateNodeExpansionOnView={updateNodeExpansion}
                          />
                        );
                      })}
                  </g>
                );
              }}
            </Sankey>
          )}
        </div>
      </div>

      {modalData && (
        <RLModalWindow
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          modalData={modalData}
        />
      )}

      {error && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}

      <MotifProgressLoader show={initialLoader} className={clsx(styles.plainLoader)} />
    </>
  );
};
export default RegulatoryLineagePage;
