import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from 'shared/core/IError';
import { GET_HOMEPAGE_DATA, GET_SAPHOMEPAGE_DATA } from 'shared/helpers/constants';
import { ISapHomePageDataItem } from 'shared/models/sapHomePageModel';
import { homePageRequests } from 'shared/utils/requests/homePage';

export const fetchSapHomePageData = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  GET_HOMEPAGE_DATA,
  async (_, thunkApi) => {
    const response = await homePageRequests.getSapHomePageData();
    if (response.isSuccess) {
      const data = response.getValue();
      return data;
    }
    return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
  }
);

export const fetchNonSAPHomePageData = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  GET_SAPHOMEPAGE_DATA,
  async (_, thunkApi) => {
    const response = await homePageRequests.getHomePageData();
    if (response.isSuccess) {
      const data = response.getValue();
      return data;
    }
    return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
  }
);

interface ISapHomePageSliceState {
  selectedRegulation?: string;
  selectedOAMetrics?: string;
  selectedOAMCheckbox?: string[];
  sapHomePageData: ISapHomePageDataItem;
}

const initialState: ISapHomePageSliceState = {
  sapHomePageData: { regulations: [], metricExplorer: [], metricsCount: [], overlapAnalysis: {} }
};

const sapHomePageSlice = createSlice({
  name: 'sapHomePageSlice',
  initialState,
  reducers: {
    setRegulationSelected(state: ISapHomePageSliceState, action: PayloadAction<string>) {
      state.selectedRegulation = action.payload;
    },
    setOAMetricsSelected(state: ISapHomePageSliceState, action: PayloadAction<string>) {
      state.selectedOAMetrics = action.payload;
    },
    setOAMCheckboxSelected(state: ISapHomePageSliceState, action: PayloadAction<string[]>) {
      state.selectedOAMCheckbox = action.payload;
    }
  },
  extraReducers: (reducers) => {
    reducers.addCase(fetchSapHomePageData.fulfilled, (state, { payload }) => {
      state.sapHomePageData = { ...state.sapHomePageData, ...payload };
    });
    reducers.addCase(fetchNonSAPHomePageData.fulfilled, (state, { payload }) => {
      state.sapHomePageData.overlapAnalysis = payload.overlapAnalysis;
    });
  }
});

export const { setRegulationSelected, setOAMetricsSelected, setOAMCheckboxSelected } =
  sapHomePageSlice.actions;

export default sapHomePageSlice.reducer;
