import clsx from 'clsx';
import React from 'react';
import { REGULATIONS_COLOR_CODE } from 'shared/helpers/constants';
import { FrameworkBarChartProps } from 'shared/models/HomePageModel';
import { convertToNormalString } from 'shared/utils/utils';

import styles from './frameworkBarChart.module.scss';

const FrameworkBarChart = ({
  heading,
  overlapping,
  regulationName
}: FrameworkBarChartProps): JSX.Element => {
  const sum = overlapping.reduce(
    (sum, child) => sum + (child.percentage ? child.percentage : 0),
    0
  );

  // Determine if there is only one regulation
  const checkOverlap = overlapping.length === 1;

  return (
    <div className={clsx('motif-col-xs', 'no-padding', styles.frameworkContainer)}>
      <div className={clsx(styles.overlapName)}>
        <p>{heading}</p>
      </div>
      <div className={clsx(styles.barContainer)}>
        {overlapping.map((obj) => {
          // Calculate width based on the checkOverlap condition
          let widthStyle = '4%';
          if (sum !== 0 && obj.percentage !== 0) {
            widthStyle = checkOverlap
              ? `${Math.max(obj.percentage + 5, 7)}%`
              : `${Math.max(obj.percentage + 7)}%`;
          }

          const titleValue = `${obj.regulationName} - ${obj.percentage}%`.toUpperCase();
          return (
            <div
              className={clsx(styles.bars)}
              key={obj.regulationName}
              style={{
                width: widthStyle,
                background:
                  REGULATIONS_COLOR_CODE[
                    convertToNormalString(obj.regulationName) as keyof typeof REGULATIONS_COLOR_CODE
                  ]
              }}>
              <span title={titleValue}>{obj.percentage}%</span>
            </div>
          );
        })}
        <div className={clsx(styles.regulationName)}>{regulationName.toUpperCase()}</div>
      </div>
    </div>
  );
};
export default FrameworkBarChart;
