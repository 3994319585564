import { Result } from 'shared/core/Result';
import { homePageDataUrl, overlapAnalysisApi, sapHomePageDataUrl } from 'shared/helpers/constants';
import { HomePageApiResponse, OverlapAnalysisModal } from 'shared/models/HomePageModel';
import { ISapHomePageDataItem } from 'shared/models/sapHomePageModel';
import httpClient, { httpClientDotNet } from 'shared/utils/httpClient';

import { IHomePageRequests } from './IHomePageRequests';

export class HomePageRequests implements IHomePageRequests {
  async getHomePageData(): Promise<Result<HomePageApiResponse>> {
    try {
      const response = await httpClientDotNet.get<any>(homePageDataUrl);

      if (response.status == 200) {
        const data = response.data;
        return Result.ok<any>(data);
      } else {
        return Result.fail<any>(response.data?.error || 'No data found');
      }
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }

  async formatOverlapAnalysisData(inputData: any) {
    if (inputData) {
      const data = {};
      for (const val of inputData) {
        Object.assign(data, val);
      }
      return data;
    }
    return [];
  }

  async getOverlapAnalysis(regulationName: string): Promise<Result<OverlapAnalysisModal>> {
    try {
      const response = await httpClientDotNet.get<any>(
        overlapAnalysisApi.analysisByRegulationUrl(regulationName)
      );
      if (response.status == 200) {
        const data = response.data;
        return Result.ok<any>(data);
      } else {
        return Result.fail<any>(response.data?.error || 'No data found');
      }
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }

  async getSapHomePageData(): Promise<Result<ISapHomePageDataItem>> {
    try {
      const response = await httpClient.get<any>(sapHomePageDataUrl);
      if (response.data?.status == 'OK') {
        const data = JSON.parse(response.data?.data);
        return Result.ok<any>(data);
      } else {
        return Result.fail<any>(response.data?.data?.error || 'No data found');
      }
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }
}
