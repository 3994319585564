import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dataSourcingApi } from 'shared/helpers/constants';
import { httpClient } from 'shared/utils/httpClient';

import { RootState } from '../store';

export interface DataSourceGridSubDataProps {
  module: string;
  table: string;
  field: string;
  fieldName: string;
}

export interface DataSourceGridDataProps {
  id: string;
  scenario: string;
  calculation: string;
  logic: string;
  fieldMapping: string;
  primaryDisclosureMetric: string;
  dataAttributes: DataSourceGridSubDataProps[];
}

export interface DataSourceGridState {
  disclosures?: DataSourceDisclosure[];
  selectedDisclosures?: DataSourceDisclosure[];
  domains?: DataSourceDomain[];
  selectedDomains?: DataSourceDomain[];
  scenarios?: DataSourceScenario[];
  selectedScenarios?: DataSourceScenario[];
  dataSourceGridData?: DataSourceGridDataProps[];
  isGridLoading?: boolean;
}

export interface DataSourceDisclosure {
  disclosureName?: string;
  topicName?: string;
  pillarName?: string;
  regulationName?: string;
}

export interface DataSourceDomain {
  disclosureName?: string;
  topicName?: string;
  pillarName?: string;
  regulationName?: string;
  domainName?: string;
}

export interface DataSourceScenario {
  disclosureName?: string;
  topicName?: string;
  pillarName?: string;
  regulationName?: string;
  domainName?: string;
  scenarioName?: string;
  scenarioId?: string;
}

interface DisclosureReqParam {
  regulationName: string;
  pillarName: string;
  topicName: string;
}

export const fetchDisclosures = createAsyncThunk<
  DataSourceDisclosure[],
  DisclosureReqParam | undefined
>('dataSourceGrid/fetchDisclosures', async (requestDetails) => {
  return httpClient
    .post(dataSourcingApi.disclosure, {
      items: [
        {
          regulationName: requestDetails?.regulationName,
          pillarName: requestDetails?.pillarName,
          topicName: requestDetails?.topicName
        }
      ]
    })
    .then(({ data }) => {
      return data;
    });
});

export const fetchDomains = createAsyncThunk<DataSourceDomain[], DataSourceDisclosure[]>(
  'dataSourceGrid/fetchDomains',
  async (requestDetails) => {
    return httpClient
      .post(dataSourcingApi.domain, {
        items: requestDetails
      })
      .then(({ data }) => {
        return data;
      });
  }
);

export const fetchScenarios = createAsyncThunk<
  DataSourceScenario[],
  DataSourceDomain[] | undefined
>('dataSourceGrid/fetchScenarios', async (requestDetails) => {
  return httpClient
    .post(dataSourcingApi.scenario, {
      items: requestDetails
    })
    .then(({ data }) => {
      return data;
    });
});

export const fetchDataSourceGridDownload = createAsyncThunk<
  DataSourceGridDataProps[],
  DataSourceScenario[] | undefined,
  { state: RootState }
>('dataSourceGrid/fetchDataSourceGridDownload', async (requestDetails) => {
  return httpClient
    .post(
      dataSourcingApi.downloadData,
      {
        items: requestDetails
      },
      {
        responseType: 'blob'
      }
    )
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data_sourcing_grid_excel.xlsx');
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
      return data;
    });
});

export const fetchDataSourceGridData = createAsyncThunk<
  DataSourceGridDataProps[],
  DataSourceScenario[] | undefined,
  { state: RootState }
>('dataSourceGrid/fetchDataSourceGridData', async (requestDetails) => {
  return httpClient
    .post(dataSourcingApi.gridData, {
      items: requestDetails
    })
    .then(({ data }) => {
      return data.data;
    });
});

const initialState: DataSourceGridState = {
  selectedDisclosures: [],
  selectedDomains: [],
  selectedScenarios: [],
  scenarios: [],
  domains: [],
  disclosures: [],
  dataSourceGridData: []
};

const dataSourceGridSlice = createSlice({
  name: 'dataSourceGrid',
  initialState,
  reducers: {
    setDataSourcingDisclosures: (
      state,
      action: PayloadAction<DataSourceDisclosure[] | undefined>
    ) => {
      if (action.payload && action.payload.length > 0) state.selectedDisclosures = action.payload;
      else {
        state.selectedDisclosures = [];
        state.selectedDomains = [];
        state.domains = [];
        state.selectedScenarios = [];
        state.scenarios = [];
        state.dataSourceGridData = [];
      }
    },
    setDataSourcingDomains: (state, action: PayloadAction<DataSourceDomain[] | undefined>) => {
      if (action.payload && action.payload.length > 0) state.selectedDomains = action.payload;
      else {
        state.selectedDomains = [];
        state.selectedScenarios = [];
        state.scenarios = [];
        state.dataSourceGridData = [];
      }
    },
    setDataSourcingScenarios: (state, action: PayloadAction<DataSourceScenario[] | undefined>) => {
      if (action.payload && action.payload.length > 0) state.selectedScenarios = action.payload;
      else {
        state.selectedScenarios = [];
        state.dataSourceGridData = [];
      }
    },
    resetData: (state) => {
      state.disclosures = [];
      state.selectedDisclosures = [];
      state.selectedDomains = [];
      state.domains = [];
      state.selectedScenarios = [];
      state.scenarios = [];
      state.dataSourceGridData = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchDataSourceGridData.fulfilled, (state, action) => {
      state.dataSourceGridData = action.payload;
      state.isGridLoading = false;
    });
    builder.addCase(fetchDataSourceGridData.rejected, (state) => {
      state.dataSourceGridData = undefined;
      state.scenarios = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchDataSourceGridData.pending, (state) => {
      state.isGridLoading = true;
    });
    builder.addCase(fetchDataSourceGridDownload.fulfilled, (state) => {
      state.isGridLoading = false;
    });
    builder.addCase(fetchDataSourceGridDownload.rejected, (state) => {
      state.isGridLoading = false;
    });
    builder.addCase(fetchDataSourceGridDownload.pending, (state) => {
      state.isGridLoading = true;
    });
    builder.addCase(fetchDisclosures.fulfilled, (state, action) => {
      state.disclosures = action.payload;
      state.domains = [];
      state.scenarios = [];
      state.selectedDisclosures = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchDisclosures.rejected, (state) => {
      state.domains = [];
      state.disclosures = [];
      state.scenarios = [];
      state.selectedDisclosures = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchDisclosures.pending, (state) => {
      state.isGridLoading = true;
      state.disclosures = [];
      state.domains = [];
      state.scenarios = [];
      state.selectedDisclosures = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
    });
    builder.addCase(fetchDomains.fulfilled, (state, action) => {
      state.domains = action.payload;
      state.scenarios = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchDomains.rejected, (state) => {
      state.scenarios = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchDomains.pending, (state) => {
      state.isGridLoading = true;
      state.scenarios = [];
      state.selectedDomains = [];
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
    });
    builder.addCase(fetchScenarios.fulfilled, (state, action) => {
      state.scenarios = action.payload;
      state.selectedScenarios = [];
      state.dataSourceGridData = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchScenarios.rejected, (state) => {
      state.dataSourceGridData = [];
      state.selectedScenarios = [];
      state.isGridLoading = false;
    });
    builder.addCase(fetchScenarios.pending, (state) => {
      state.isGridLoading = true;
      state.dataSourceGridData = [];
      state.selectedScenarios = [];
    });
  }
});

export const {
  setDataSourcingDisclosures,
  setDataSourcingDomains,
  setDataSourcingScenarios,
  resetData
} = dataSourceGridSlice.actions;
export default dataSourceGridSlice.reducer;
