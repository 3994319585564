import React, { useState } from 'react';

import { MotifIcon } from '@ey-xd/motif-react';

import styles from './handleZoom.module.scss';
import { LineagePageIcons } from 'images/lineagePageIcons';
import { ZoomTypes } from 'shared/models/LineagePageModel';

interface HandleZoomProps {
  zoomFunction: any;
}

const HandleZoom = ({ zoomFunction }: HandleZoomProps): JSX.Element => {
  const [scale, setScale] = useState<number>(100);
  const buttonClicked = (type: ZoomTypes) => {
    let scaleIs = scale;
    if (type == ZoomTypes.IN) {
      if (scale > 50) {
        scaleIs -= 10;
      }
    } else {
      if (scale < 150) {
        scaleIs += 10;
      }
    }
    if (scaleIs != scale) {
      setScale(scaleIs);
      zoomFunction(ZoomTypes.IN, scale);
    }
  };

  return (
    <div className={styles.zoomBox}>
      <MotifIcon
        onClick={() => buttonClicked(ZoomTypes.IN)}
        className={styles.zoomIn}
        style={{ borderRadius: '20px 0 0 20px' }}
        src={`data:image/svg+xml;base64,${LineagePageIcons.minusSvg}`}
        data-testid="zoom-in"
      />
      <span className={styles.zoomPercentage}>{scale}%</span>
      <MotifIcon
        onClick={() => buttonClicked(ZoomTypes.OUT)}
        className={styles.zoomIn}
        style={{ borderRadius: '0 20px 20px 0' }}
        src={`data:image/svg+xml;base64,${LineagePageIcons.addSvg}`}
        data-testid="zoom-out"
      />
    </div>
  );
};
export default HandleZoom;
