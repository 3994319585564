import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { CacheAxiosResponse, setupCache } from 'axios-cache-interceptor';
import { BACKEND_API_URL, BACKEND_DOTNET_URL } from 'shared/helpers/constants';
import {
  HttpHeader,
  HttpHeaderKey,
  HttpHeaderValue,
  HttpStatusCode
} from 'shared/models/apiStatus.enum';

import { acquireAccessToken } from './msalApiCalls';

const instance = axios.create({
  baseURL: BACKEND_API_URL,
  headers: {
    [HttpHeader.CONTENT_TYPE]: HttpHeaderValue.ApplicationJson
  }
});

const httpClient = setupCache(instance);

// Add a request interceptor
httpClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (!config.headers) (config.headers as AxiosHeaders).set({});
    const ACCESS_TOKEN = await acquireAccessToken();
    config.headers[HttpHeaderKey.Authorization] = 'Bearer ' + ACCESS_TOKEN;
    config.headers[HttpHeader.CONTENT_TYPE] = HttpHeaderValue.ApplicationJson;
    return config;
  },
  (error) => {
    // Do something with request error
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (res: CacheAxiosResponse<any, any>) => {
    if (typeof res.data.data === 'string') {
      res.data.parsedData = JSON.parse(res.data.data);
    } else {
      res.data.parsedData = null;
    }
    return res;
  },
  (err) => {
    if (err.response.status === HttpStatusCode.Unauthorized) {
      alert('The session has become inactive. Please refresh the page to continue.');
    }
    return Promise.reject(err);
  }
);

const instanceDotNet = axios.create({
  baseURL: BACKEND_DOTNET_URL,
  headers: {
    [HttpHeader.CONTENT_TYPE]: HttpHeaderValue.ApplicationJson
  }
});

const httpClientDotNet = setupCache(instanceDotNet);

// Add a request interceptor
httpClientDotNet.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (!config.headers) (config.headers as AxiosHeaders).set({});
    const ACCESS_TOKEN = await acquireAccessToken();
    config.headers[HttpHeaderKey.Authorization] = 'Bearer ' + ACCESS_TOKEN;
    config.headers[HttpHeader.CONTENT_TYPE] = HttpHeaderValue.ApplicationJson;
    return config;
  },
  (error) => {
    // Do something with request error
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

httpClientDotNet.interceptors.response.use(
  (res: CacheAxiosResponse<any, any>) => {
    if (typeof res.data.data === 'string') {
      res.data.parsedData = JSON.parse(res.data.data);
    } else if (typeof res.data === 'string') {
      res.data = {
        status: 'OK',
        parsedData: null
      };
    } else {
      res.data.parsedData = null;
    }
    return res;
  },
  (err) => {
    if (err.response.status === HttpStatusCode.Unauthorized) {
      alert('The session has become inactive. Please refresh the page to continue.');
    }
    return Promise.reject(err);
  }
);

export { httpClient, httpClientDotNet };

export default httpClient;
