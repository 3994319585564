import * as venn from '@upsetjs/venn.js';
import * as d3 from 'd3';
import React, { useEffect } from 'react';
import { REGULATIONS_COLOR_CODE } from 'shared/helpers/constants';
import { convertToNormalString } from 'shared/utils/utils';

import styles from './vennDiagram.module.scss';

interface VennDiagramPopupProps {
  dataSet: any;
  chartWidth: number;
  chartHeight: number;
}

const VennDiagram = ({ dataSet, chartWidth, chartHeight }: VennDiagramPopupProps): JSX.Element => {
  const actualWidth = chartWidth;
  const actualHeight = chartHeight;
  useEffect(() => {
    if (dataSet) {
      try {
        const data = venn.layout(dataSet, { width: actualWidth, height: actualHeight });

        // reset the existing data before inserting new data
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        document.getElementById('venn')!.innerHTML =
          '<defs><filter x="-0.25" y="-0.25" width="1.5" height="1.5" id="solid"><feFlood flood-color="rgba(26,26,36,90%)" result="bg" /><feMerge><feMergeNode in="bg" /><feMergeNode in="SourceGraphic" /></feMerge></filter></defs>';

        const g = d3
          .select('#venn')
          .selectAll('g')
          .data(data)
          .join((enter) => {
            const g = enter.append('g');
            g.attr('class', 'allCircles');
            g.append('title');
            g.append('path');
            g.append('text');
            g.append('text');
            g.append('text');
            g.append('text');
            g.append('text');
            return g;
          });
        g.select('title')
          .text((d) => d.data.sets.toString())
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y);
        g.select('text:nth-of-type(1)') // this one to show the percentage or the regulation name
          .text((d) => {
            const data: any = d.data;
            if (data.sets.length > 1) {
              return data.percentage == null ? null : `${data.viewPercentage}%`;
            } else if (data.size != 0) {
              return `${d.data.sets.toString().toUpperCase()}`;
            } else {
              return null;
            }
          })
          .attr('font-size', '14')
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y - 15);
        g.select('text:nth-of-type(2)') // this one to show individual count
          .text((d) => {
            const data: any = d.data;
            if (data.sets.length == 1) {
              return data.totalOverlapCount;
            } else {
              return null;
            }
          })
          .attr('font-size', '12')
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y + 5);
        g.select('text:nth-of-type(3)') // this one to show overlap count
          .filter((d) => d.data.size != 0)
          .text((d) => {
            const data: any = d.data;
            if (data.sets.length > 1) {
              return ` ${data.totalOverlapCount} `;
            }
            return null;
          })
          .attr('font-size', '12')
          .attr('filter', 'url(#solid)')
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y + 7);
        g.select('text:nth-of-type(4)') // this one to show text
          .text((d) => {
            const data: any = d.data;
            if (data.sets.length == 1) {
              return 'Total metrics';
            } else {
              return null;
            }
          })
          .attr('font-size', '12')
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y + 23);
        g.select('text:nth-of-type(5)') // this one to show text
          .text((d) => {
            const data: any = d.data;
            if (data.sets.length == 1) {
              return 'analyzed';
            } else {
              return null;
            }
          })
          .attr('font-size', '12')
          .attr('x', (d) => d.text.x)
          .attr('y', (d) => d.text.y + 36);
        g.select('path')
          .attr('d', (d) => d.path)
          .style('fill', (d) => {
            if (d.circles.length === 1) {
              const key: string = d.circles[0].set;
              return REGULATIONS_COLOR_CODE[
                convertToNormalString(key) as keyof typeof REGULATIONS_COLOR_CODE
              ];
            }
            return 0.1;
          });
      } catch (err) {
        console.log('🚀 ~ useEffect ~ err:', err);
      }
    }
  }, [dataSet, chartWidth]);

  return (
    <svg
      data-testid="venn-diagram-svg"
      id="venn"
      width={actualWidth}
      height={actualHeight}
      className={styles.diagramContainer}></svg>
  );
};
export default VennDiagram;
